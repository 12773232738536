
.live-casino-banner img{aspect-ratio: auto;}
.game-provider-list{margin: 10px 0 0;}
.game-provider-list .swiper-slide { background: var(--light-color4); border: 1px solid var(--primary-color);; white-space: nowrap; margin: 0; border-radius: 6px; transform: skew(-14deg, 0deg) !important; margin: 0 10px;}
.game-provider-list .swiper-slide:hover, .game-provider-list .swiper-slide.active{background: var(--primary-gradient); }
.game-provider-list .swiper-slide span { white-space: nowrap; padding: 10px; line-height: normal; font-size: 22px; font-weight: 700; text-transform: uppercase; margin: 0; transform: skew(14deg, 0deg); background: transparent; border: 0; height: 61px; display: flex; align-items: center; min-width: 130px; justify-content: center; cursor: pointer;}
.game-provider-list .swiper-slide:hover span, .game-provider-list .swiper-slide.active span{color: var(--white-color);}
.search-with-swiper { background: var(--white-color); padding: 10px 120px 10px 10px !important; margin: 10px 0!important; display: flex; position: relative; z-index: 111; border-radius: 20px;}
.search-with-swiper .search-dropdown{top: 0; height: 100%; padding: 10px;}
.search-with-swiper .search-dropdown button .icon, .game-category-list .swiper-slide .game-tab .icon{width: 32px; height: 32px; display: flex; align-items: center; justify-content: center;}
.search-with-swiper .game-category-list { margin: 0; display: flex; }
.game-category-list .swiper-slide .game-tab { display: flex; flex-direction: column; align-items: center; justify-content: center; background: var(--light-color3); padding: 8px 20px; border-radius: 8px; font-size: 18px; color: var(--primary-color); gap: 4px 0; cursor: pointer; height: 100%;}
.game-category-list .swiper-slide .game-tab.active { background: var(--primary-gradient); color: var(--white-color);}
.search-dropdown { display: flex; min-width: 79px; position: absolute; z-index: 1; border-radius: 8px; top: 10px; right: 0; height: 79px; padding: 0 10px; background: var(--white-color);}
.search-dropdown button { display: flex; flex-direction: column; align-items: center; justify-content: center; background: #E8EAF6; padding: 8px 20px; border-radius: 8px; font-size: 18px; color: var(--primary-color); gap: 4px 0; }
.dropdown.search-dropdown .dropdown-menu { min-width: 350px; box-shadow: 0 0 90px 0px #000; right: 15px; border-radius: 0;}
.dropdown.search-dropdown .dropdown-menu input{ border-radius: 0;}
.dropdown.search-dropdown .dropdown-menu .search-result { position: absolute; top: 100%; left: 0; width: 100%; background: #ffffff; -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.2); -moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.2); box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.2); border-radius: 0 0 10px 10px; z-index: 99; display: inline-block; max-height: 315px; overflow: hidden; overflow-y: auto; padding: 15px; }
.dropdown.search-dropdown .dropdown-menu .search-result li { width: 100%; display: inline-block; list-style: none; padding: 0; margin: 0 0 5px; font-weight: 400; font-size: 15px; line-height: normal; color: var(--text-color); border: 0; text-align: left; cursor: pointer;}
.dropdown.search-dropdown .dropdown-menu .search-result li:hover{color: var(--primary-color);}
.game-provider-list, .search-with-swiper .game-category-list { overflow: auto !important; display: flex !important; padding-bottom: 2px !important;}

.casino-grid-sec{padding: 15px; margin: 15px 0 0;}
.casino-grid { gap: 10px; display: grid; grid-template-columns: repeat(5, 1fr); }
.casino-grid .col{position: relative; padding: 0; border-radius: 15px; width: 100%; overflow: hidden;}
.casino-grid .col img{ aspect-ratio: 1 / 0.691; object-fit: cover; object-position: center; background: var(--black-color);}
.casino-grid .title { position: absolute; bottom: 0; left: 0; width: 100%; color: var(--white-color); display: flex; justify-content: space-between; padding: 5px 10px; z-index: 2; line-height: 1; font-size: 13px;}
.casino-grid .title::after{content: ''; background: var(--primary-color); position: absolute; left: 0; top: 0; width: 100%; height: 100%; opacity: 0.7; pointer-events: none; z-index: -1;}
.casino-grid .title i { margin: 3px 0 0; }
.casino-grid .login-btn-overlay { position: absolute; left: 0; top: -100%; z-index: 1; width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; z-index: 1; transition: 0.5s all ease-in-out;}
.casino-grid .col:hover .login-btn-overlay, .casino-grid .col:focus .login-btn-overlay, .casino-grid .col:active .login-btn-overlay  { top:0; transition: 0.5s all ease-in-out;}
.casino-grid .login-btn-overlay::after{content: ''; background: var(--black-color); position: absolute; left: 0; top: 0; width: 100%; height: 100%; opacity: 0.5; pointer-events: none; z-index: -1;}
.casino-grid .login-btn-overlay .btn { background: var(--white-color); color: var(--text-color); font-weight: 400; text-transform: uppercase;}

.game-iframe-sec { position: fixed; bottom: 0; left: 0; width: 100%; height: calc(100% - 89px); background: #000; }
.game-iframe-sec iframe{border: 0; width: 100%; height: 100%;}

@media (max-width:1280px) {
    .game-provider-list .swiper-slide a { line-height: normal; font-size: 20px; height: 50px; min-width: 110px; }
    .game-provider-list .swiper-slide span { font-size: 20px; height: 55px; min-width: 120px; }
    .search-with-swiper .search-dropdown button .icon, .game-category-list .swiper-slide .game-tab .icon{width: 30px;}
    .game-category-list .swiper-slide div{font-size: 16px;}
    .search-with-swiper{padding-right: 110px !important}
    .search-dropdown button{font-size: 16px;}
}
@media (max-width:1150px) {
    .casino-grid { grid-template-columns: repeat(4, 1fr); }
    .game-iframe-sec { height: calc(100% - 72px); }
}
@media (max-width:999px) {
    .casino-grid { grid-template-columns: repeat(3, 1fr); }
    .game-provider-list .swiper-slide span { font-size: 18px; height: 50px; min-width: 100px; }
    .game-category-list .swiper-slide div{font-size: 15px; padding: 4px 10px;}
    .search-with-swiper{padding-right: 98px !important; margin: 10px -15px !important; border-radius: 0;}
    .search-dropdown button{font-size: 15px; padding: 8px 15px;}
    .game-iframe-sec { height: calc(100% - 109px); }
}
@media (max-width:767px) {
    .game-provider-list, .search-with-swiper .game-category-list{padding-bottom: 0 !important;}
    .game-provider-list .swiper-slide{margin: 0 4px;}
    .game-provider-list .swiper-slide span { font-size: 14px; height: 34px; min-width: 70px; }
    .search-with-swiper .search-dropdown button .icon, .game-category-list .swiper-slide .game-tab .icon{width: 24px; height: 26px;}
    .search-with-swiper .search-dropdown { padding: 7px; }
    .search-with-swiper { padding: 7px 80px 7px 7px !important; }
    .search-dropdown button{padding: 8px 10px;}
    .game-category-list .swiper-slide div{font-size: 14px; padding: 4px 8px;}
    .game-category-list .swiper-slide{margin-right: 6px !important;}
    .game-category-list .swiper-slide .game-tab { padding: 6px 15px; font-size: 15px; }
    .dropdown.search-dropdown .dropdown-menu{min-width: 300px;}
    .game-iframe-sec { height: calc(100% - 108px); }
    .casino-grid{gap: 5px;}
    .casino-grid .title{font-size: 10px;}
    .casino-grid .col img{border-radius: 10px;}
}
@media (max-width:480px) {
    .search-with-swiper{margin: 10px -5px !important;}
    .game-provider-list .swiper-slide span { font-size: 10px; height: 24px; min-width: 54px; }
    .game-provider-list .swiper-slide{margin: 0 3px;}
    .game-category-list .swiper-slide .game-tab{font-size: 12px;}
    .game-category-list .swiper-slide .game-tab { padding: 5px 8px; font-size: 11px; gap: 2px 0; min-width: 40px; }
    .search-with-swiper { margin: 5px -5px 0!important; padding: 5px 65px 5px 5px !important; }
    .search-with-swiper .search-dropdown button .icon, .game-category-list .swiper-slide .game-tab .icon { width: 19px; height: 27px; }
    .game-category-list .swiper-slide{margin-right: 4px !important;}
    .search-with-swiper .search-dropdown{padding: 5px; min-width: 65px;}
    .search-dropdown button{font-size: 12px;}
    .game-iframe-sec { height: calc(100% - 97px); }
    .casino-grid .title{font-size: 8px;}
}