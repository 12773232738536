.home-grid{display: flex; flex-wrap: wrap;}
.home-grid .col{width: 20%; padding:5px 5px;}
.home-grid .col img{border-radius: 15px; width: 100%;}
@media (max-width:999px) {
    .home-grid .col{width: 25%;}
}
@media (max-width:767px) {
    .home-grid .col{width: 33.33%;}
}
@media (max-width:480px) {
    .home-grid .col{width: 50%;}
    .home-grid .col { padding: 3px; }

}