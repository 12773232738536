.home-grid-sec{margin: 10px 0 0; border-radius: 30px; background-color: var(--white-color); border: 1px solid var(--light-color); padding: 10px;}
.home-grid-sec h2{margin: 0;}
.home-grid-sec h2.home-grid-title { margin-bottom: 0px; color: var(--primary-color); font-weight: 600; font-size: 30px; position: relative; text-transform: uppercase; display: flex; align-items: center; gap: 10px; padding:0 5px; z-index: 11;}
.home-grid-sec h2.home-grid-title::before {content: ''; height: 25px; width: 8px; display: flex; align-items: center; justify-content: center; background-color: var(--primary-color); }
.home-grid-sec h2.home-grid-title::after { content: attr(title); position: absolute; font-size: 200%; top: -10px; left: 0px; line-height: 1; z-index: -1; opacity: 0.15;}

@media (max-width:999px) {
    .home-grid-sec h2.home-grid-title{font-size: 20px;}
    .home-grid-sec h2.home-grid-title::after { font-size: 160%; top: -2px; }
    .home-grid-sec h2.home-grid-title{font-size: 20px;}
}
@media (max-width:767px) {
    .home-grid .col{padding: 3px;}
}
@media (max-width:480px) {
    .home-grid-sec { margin: 6px 0 0; border-radius: 15px; padding: 6px; }
    .home-grid-sec h2.home-grid-title{font-size: 18px;}
    .home-grid-sec h2.home-grid-title::after { font-size: 140%; top: 0; }
    .home-grid-sec h2.home-grid-title::before{width: 6px;}
}