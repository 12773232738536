footer.footer .footer-row { padding:2em 15px 16px; border-radius: 30px; border: 1px solid var(--light-color); background-color: var(--white-color); text-align: center; margin: 10px 0;}
.footer-logo a { display: inline-block; width: 137px; margin: 0 0 10px;}
.footer-content p { line-height: 1.28; margin: 0 auto 0; width: 78%; }
.footer-contact{margin: 16px 0 0;}
.footer-contact h3 { color: var(--primary-color); font-size: 15px; font-weight: 600; margin: 0; }
.footer-contact ul.footer-social { display: flex; align-items: center; justify-content: center; }
.footer-contact ul.footer-social li { width: 30px; margin: 8px 5px; }
.footer-contact .contact-num { display: flex; align-items: center; justify-content: center; gap: 7px; }
.footer-links { display: flex; align-items: center; justify-content: center; gap:0 10px; flex-wrap: wrap; margin: 10px 0 0; }
.footer-links .link a{color: var(--primary-color); line-height: 1.3;}
.footer-links .link:not(:last-child):after { content: '|'; display: inline-block; vertical-align: middle; line-height: 1; margin-left: 5px; color: var(--primary-color);}

@media (max-width:480px) {
    .footer-content p{width: 100%;}
    footer.footer .footer-row{padding: 2em 8px 16px;}

}