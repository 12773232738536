.account-sec { padding: 15px 0; }
.account-sec .react-datepicker-wrapper{width: 100%;}
.acount-header-filter { display: flex; align-items: center; justify-content: space-between; gap: 10px; padding: 1em 1em; border-radius: 30px; background-color: var(--white-color); border: 1px solid var(--border-color); flex-wrap: wrap; }
.acount-header-filter .title-wraper { display: flex; align-items: center; gap: 10px; position: relative; z-index: 20; }
.acount-header-filter .title-wraper .v-border { height: 20px; width: 8px; display: flex; align-items: center; justify-content: center; background-color: var(--primary-color); }
.acount-header-filter .title { margin-bottom: 0px; color: var(--primary-color); font-weight: 600; font-size: 25px; position: relative; text-transform: uppercase; display: flex; align-items: center; gap: 10px;}
.acount-header-filter .title.back-icon{cursor: pointer;}
.acount-header-filter .right-filter { display: flex; align-items: center; gap: 20px; flex-wrap: wrap; }
.acount-header-filter .right-filter input, .acount-header-filter .right-filter select { background-color: var(--light-color2); color: var(--text-color); font-size: 15px; border: none; border-radius: 30px; min-width: 140px; height: 32px; padding: 5px 10px; font-weight: 400; font-family: var(--primary-font); max-width: 185px; min-width: 150px;}
.acount-header-filter .right-filter .btns{display: flex; gap: 20px; align-items: center; white-space: nowrap;}
.acount-header-filter .right-filter .btns .btn { font-weight: 400; font-size: 16px; width: 100%; gap: 7px; line-height: 1;}
.acount-header-filter .right-filter .bet-amount { display: flex; align-items: center; gap: 10px; }
.acount-header-filter .right-filter .bet-amount span { display: flex; align-items: center; gap: 2px; }
.account-table { padding: 1em 1em; border-radius: 30px; background-color: var(--white-color); border: 1px solid var(--border-color); margin-top: 20px; overflow: auto;}
.account-table thead { text-align: center; background: var(--primary-gradient); }
.account-table thead th:first-child{ border-top-left-radius: 30px; border-bottom-left-radius: 30px; }
.account-table thead th:last-child{ border-top-right-radius: 30px; border-bottom-right-radius: 30px; }
.account-table thead th{color: var(--white-color); border: 0; font-size: 15px; font-weight: 600; padding: 10px 10px; white-space: nowrap; line-height: 1.5; text-align: center;}
.account-table tbody td { padding: 5px 5px; border: 0; border-bottom: 1px solid var(--border-color); line-height: 1.5; text-align: center;}
.account-table tbody tr:nth-child(odd) { background: var(--light-color2); }
.account-table tbody tr:hover{background: var(--light-color);}
.account-table tbody tr.account-statment-expand.back{background: var(--lightblue-color) !important;}
.account-table tbody tr.account-statment-expand.lay{background: var(--lightpink-color) !important;}
.account-table td.arrow:after{content: ''; border-left: 2px solid #000; border-bottom: 2px solid #000; display: inline-block; padding: 3px; transform: rotate(-45deg);}
.account-table tr.address-header{background: var(--border-color);}

.account-table table.user-data-table tbody tr:hover{background: var(--yellow-color);}
.account-table tr.inner-heading td { font-size: 16px; font-weight: 500; }

@media (max-width:999px) {
    .acount-header-filter .right-filter > div{min-width: 150px; flex: auto;}
    .acount-header-filter .right-filter input, .acount-header-filter .right-filter select{width: 100%; max-width: 100%;}
    .account-table thead th.transaction { min-width: 140px; }
    .account-table thead th.date, .account-table thead th.time, .account-table thead th.event, .account-table thead th.game, .account-table thead th.match, .account-table thead th.beton{ min-width: 210px; }
}
@media (max-width:767px) {
    .acount-header-filter .title{font-size: 20px;}
    .acount-header-filter .title-wraper .v-border{width: 5px;}
    .acount-header-filter .right-filter { gap: 10px 16px; }
    .account-table tr.inner-heading td{font-size: 14px;}
}
@media (max-width:480px) {
    .acount-header-filter .right-filter > div { width: 100%; flex: auto; }
}