.mobile-bottom-menu{display: none; min-height: 50px;}
.mobile-bottom-menu .menu-links { display: flex; background: var(--white-color); justify-content: space-around; padding: 3px 0;     position: fixed; bottom: 0; left: 0; width: 100%; z-index: 111;}
.mobile-bottom-menu .menu-links a, .mobile-bottom-menu .menu-links span { display: flex; align-items: center; justify-content: center; flex-direction: column; font-size: 16px; font-weight: 500; color: var(--text-color); line-height: 1.2; cursor: pointer;}
.mobile-bottom-menu .menu-links a.menu-popup-icon { width: 44px; height: 44px; background: var(--primary-color); text-align: center; border-radius: 44px; }
.bottom-menu-popup { display: block; position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: 111111; opacity: 0; visibility: hidden; pointer-events: none; transition: all 0.5s;}
.bottom-menu-popup.open{opacity: 1; visibility: visible; pointer-events: auto;}
.pop-menu-sec { padding: 10px 20px; background-color: var(--white-color); border-radius: 20px; width: fit-content; margin: auto; position: absolute; left: 0; right: 0; text-align: center; bottom: 0; transform: translateY(100%); transition: all 0.5s;}
.pop-menu-sec ul.popup-menu-links { list-style: none; display: inline-flex; align-items: center; justify-content: center; flex-direction: column; gap: 10px; margin-bottom: 0px; border-radius: 20px; }
.pop-menu-sec ul.popup-menu-links li{margin: 0;}
.pop-menu-sec ul.popup-menu-links li span{padding: 2px 9px; display: inline-block; border-radius: 30px;}
.pop-menu-sec ul.popup-menu-links li span.active{background: var(--primary-color); color: var(--white-color);}
.bottom-menu-popup.open .pop-menu-sec{bottom: 150px; transform: translateY(0%);}
.bottom-menu-popup .overlay{background: #000; opacity: 0.5; position: absolute; left: 0; top: 0; width: 100%; height: 100%;}
@media (max-width:999px) {
    .mobile-bottom-menu{display: flex;}
}