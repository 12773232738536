.rulespopup-btn{display: inline-block; vertical-align: middle; line-height: 1; cursor: pointer;}
.game-rules-popup .gr-accordian-item{border-bottom: 1px solid var(--border-color);}
.game-rules-popup .gr-accordian-title { padding: 16px; font-size: 15px; display: flex; align-items: center; color: var(--text-color); justify-content: space-between; cursor: pointer; transition: 0.5s;}
.game-rules-popup .gr-accordian-title.active svg { transform: rotate(-180deg); }
.game-rules-popup .gr-accordian-title svg { width: 20px; height: auto; }
.game-rules-popup .gr-accordian-content { padding: 16px; display: none;}
.game-rules-popup .gr-accordian-content.active{display: block;}
.game-rules-popup .gr-accordian-content .content { background-color: var(--light-color2); border-radius: 15px; padding: 20px 15px; }
.game-rules-popup .gr-accordian-content .content ol{ counter-reset: item; padding: 0; margin: 0; }
.game-rules-popup .gr-accordian-content .content ol ol{margin-top: 10px;}
.game-rules-popup .gr-accordian-content .content ol li{ display: block; position: relative; padding-left: 20px; font-size: 14px;}
.game-rules-popup .gr-accordian-content .content ol li li{ padding-left: 22px; }
.game-rules-popup .gr-accordian-content .content ol li:before { content: counters(item, ".") ". "; counter-increment: item; position: absolute; left: 0; }
.game-rules-popup .gr-accordian-content .content > ol > li { margin: 0 0 12px; }
@media (max-width:767px) {
    .game-rules-popup .gr-accordian-title{padding: 10px 0;}
    .game-rules-popup .gr-accordian-content { padding: 10px 0; }
    .game-rules-popup .gr-accordian-content .content ol li{font-size: 13px;}
}