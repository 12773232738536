
body.popup-open{overflow: hidden;}
.popup { position: fixed; top: 0; left: 0; background: #00000070; height: 100%; width: 100%; z-index: 1111111; opacity: 0; pointer-events: none; transition: all 0.4s ease-in-out; overflow: auto;}
.popup.open {opacity: 1; pointer-events: auto; transition: all 0.4s ease-in-out;}
.popup-content { position: absolute; left: 0; right: 0; margin: auto; max-width: 880px; transform: scale(0); border-radius: 30px; padding: 40px; transition: all 0.4s ease-in-out; min-height: 100vh; display: flex; align-items: center;}
.popup.open .popup-content { transform: scale(1); transition: all 0.4s ease-in-out;}
.popup .close-btn { cursor: pointer; position: absolute; right: -15px; top: -15px; width: 30px; height: 30px; background: var(--primary-color); color: var(--white-color); font-size: 25px; font-weight: 600; line-height: 27px; text-align: center; border-radius: 50%;}


.popup.alertMsgpopup { background: none; z-index: 999999999; }
.popup.alertMsgpopup .popup-content, .popup.alertMsgpopup.open .popup-content { top: 0; transform: none; min-height: auto; }
.popup.alertMsgpopup .alert-msg{ background: var(--green-color); z-index: 11; width: 100%;text-align: center; padding: 10px; font-size: 20px; color: var(--white-color); display: block; max-width: 500px; margin: auto; position: relative;}
.popup.alertMsgpopup.Error .alert-msg, .popup.alertMsgpopup .alert-msg.err{background: var(--red-color);}

.alert-progress-bar {position: absolute; bottom: 0; left: 0; height: 6px; width: 100%; overflow: hidden;}
.alert-progress-bar:before { content: ""; position: absolute; bottom: 0; right: 0; height: 100%; width: 100%; background-color: #03b303; }
.popup.alertMsgpopup.open .alert-progress-bar:before {animation: progress 4s linear forwards;}
.popup.alertMsgpopup.Error .alert-progress-bar:before, .popup.alertMsgpopup .alert-msg.err .alert-progress-bar:before{ background-color: #ad0000; }

@keyframes progress {
    100% { right: 100%; }
}