body.page-with-side-menu main.main-content, body.page-with-side-menu footer { padding-left: calc(16.66666667% - 30px - 15px); }
.side-sport-menu{display: none;}
body:not(.page-with-side-menu) .sportsmenu-sec, body:not(.page-with-side-menu) .sportmenu-bar{display: none;}
.sportsmenu-sec { position: fixed; top: 94px; left: 15px; width: calc(16.66666667% - 30px - 15px - 15px); overflow: auto; padding-bottom: 20px;}
/* .sportsmenu-sec::-webkit-scrollbar-track { -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); border-radius: 10px; background-color: #F5F5F5; }
.sportsmenu-sec::-webkit-scrollbar { width: 4px; background-color: #F5F5F5; display: none;}
.sportsmenu-sec::-webkit-scrollbar-thumb { border-radius: 10px; -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); background-color: #ccc; } */
.sportsmenu-sec .sportmenu .title { background: var(--primary-color); color: var(--white-color); padding: 5px 10px; font-size: 15px;     line-height: 1.3;}
.sportsmenu-sec .sportmenu .box { background: var(--white-color); border-radius: 15px; overflow: hidden; box-shadow: 0 0 20px 0px #00000017;     border: 1px solid #ddd;}
.sportsmenu-sec .sportmenu .box + .box{margin-top:10px;}
.sportsmenu-sec .sportmenu .box > ul { padding: 5px 0 8px; }
.sportsmenu-sec .sportmenu .box > ul > li { padding: 0; margin: 0; line-height: 1; position: relative;}
.sportsmenu-sec .sportmenu .box > ul li + li { border-top: 1px solid #ddd; margin-top: 0;}
.sportsmenu-sec .sportmenu .box > ul > li .menu_link { display: flex; gap: 6px; align-items: center; padding: 14px 10px 12px; color: var(--text-color); font-size: 14px; cursor: pointer; position: relative;}
.sportsmenu-sec .sportmenu .box > ul > li > .menu_link{text-transform: capitalize;}
.sportsmenu-sec .sportmenu .box > ul > li .menu_link img { height: 18px; }
.sportsmenu-sec .sportmenu .box > ul > li .menu_link:hover { color: var(--primary-color); }
.sportsmenu-sec .sportmenu .box > ul li.dropdown > .menu_link:after { content: ''; display: inline-block; border-left: 2px solid #333333; border-bottom: 2px solid #333; padding: 3px; transform: rotate(-45deg); cursor: pointer; transition: 0.35s all; margin-left: auto;}
.sportsmenu-sec .sportmenu .box > ul > li ul.sport-dropdown-menu{display: none; background: #f4f4f4;}
.sportsmenu-sec .sportmenu .box > ul > li ul.sport-dropdown-menu.active{display: block;}
.sportsmenu-sec .sportmenu .box > ul > li ul li .menu_link{font-size: 13px; padding-left: 30px; font-size: 90%;}
.sportsmenu-sec .sportmenu .box > ul > li ul li li .menu_link{padding-left: 45px; font-size: 85%;}
.sportsmenu-sec .sportmenu .box > ul li.dropdown.active > .menu_link:after { transform: rotate(-135deg); margin-top: 3px; }

.sportmenu-bar{ width: 18px; height: 18px; margin-right: 8px; cursor: pointer; display: none;}
.sportmenu-bar span{ width: 18px; height: 2px; display: block; background: #fff; margin: 4px 0; transition: all 0.5s; position: relative;}
button.sport-close-menu { background: var(--primary-color); color: var(--white-color); border-radius: 24px; line-height: 1; height: 24px; width: 24px; position: absolute; right: 4px; top: 4px; display: none; padding: 0; text-align: center;}
.sportsmenu-sec .sport-overlay { content: ''; background: #000; position: fixed; right: 0; top: 0; height: 100%; width: calc(100% - 260px); z-index: 1; opacity: 0.5; display: none;}


@media (max-width:1450px) {
    .sportsmenu-sec .sportmenu .box > ul > li a{font-size: 13px;}
}
@media (max-width:1280px) {
    body.page-with-side-menu main.main-content, body.page-with-side-menu footer { padding-left: calc(20% - 30px - 15px); }
    .sportsmenu-sec{ width: calc(20% - 30px - 15px - 15px);}
}
@media (max-width:1150px) {
    .sportsmenu-sec .sportmenu .box > ul > li a img { height: 16px; }
    body.page-with-side-menu main.main-content, body.page-with-side-menu footer { padding-left: 0 !important; }
    .sportmenu-bar{ display: inline-block;}
    .sportsmenu-sec { display: none; background: #f5f5f5; z-index: 11111111; left: 0; padding: 15px; height: 100% !important; top: 0 !important; width: 100%; max-width: 260px; overflow: auto; padding-top: 35px;}
    .sportsmenu-sec.open .sport-overlay { display: block;}
    .sportsmenu-sec.open{display: block;}
    button.sport-close-menu{display: block;}
}

