.browser-banner .browser-banner-grid { display: flex; }
.browser-banner .browser-banner-grid .col{ width: 50%; padding: 0 5px; position: relative;}
.browser-banner .browser-banner-grid .overlay { position: absolute; top: 50%; z-index: 11; color: #fff; transform: translateY(-50%); padding: 2rem; }
.browser-banner .browser-banner-grid .overlay h4 { font-size: 30px; color: var(--white-color); font-weight: 600; max-width: 360px; line-height: 1.2; margin:0 0 18px; }
.browser-banner .browser-banner-grid .col img{width: 100%; border-radius: 30px;}

@media (max-width:999px) {
    .browser-banner .browser-banner-grid .overlay h4{font-size: 18px;}
    .browser-banner .browser-banner-grid .overlay { padding: 1rem; max-width: 260px; }
}
@media (max-width:767px) {
    .browser-banner .browser-banner-grid{flex-wrap: wrap; gap: 6px;}
    .browser-banner .browser-banner-grid .col{width: 100%;}
}
@media (max-width:480px) {
    .browser-banner .browser-banner-grid .col{padding: 0;}
    .browser-banner .browser-banner-grid .overlay { padding: 0.69em; max-width: 227px; }
    .browser-banner .browser-banner-grid .overlay h4{font-size: 17px; margin: 0 0 10px;}
    .browser-banner .browser-banner-grid .col img { border-radius: 15px; }
}