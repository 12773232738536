.inner-match-list.sport-all-data .match-heading{background: transparent; padding: 0; font-size: 17px;}
.inner-match-list.sport-all-data .match-heading .heading-text{color: var(--text-color);}
.inner-match-list.sport-all-data .match-odds .box span{cursor: pointer;}
.inner-match-list.sport-all-data .heading .odds-heading.col2-odds-heading{width: 128px;}
.inner-match-list.sport-all-data .match-data .match-info-row .match-name{font-size: 16px;}

/* Fancy Css */
.sport-all-data.inner-match-list.fancylist .match-heading { background: #182ea61a; margin: 7px 0; padding: 0px 10px; }
.sport-all-data.inner-match-list.fancylist .match-heading .heading-text{color: var(--primary-color);}
.sport-all-data.inner-match-list.fancylist .match-info-row{padding: 5px 10px;}
.fancytab-btns { background-color: var(--primary-color); border-radius: 30px; padding: 5px 5px; display: flex; align-items: center; margin-bottom: 5px !important; gap: 10px; margin-top: 7px; flex-wrap: nowrap; overflow-x: auto; white-space: nowrap;}
.fancytab-btns button{padding: 2px 10px; transition: all 0.0s ease-in-out; font-size: 15px; color: var(--white-color); line-height: 1; background: transparent; border: 0; border-radius: 20px; font-weight: 400; width: max-content;}
.fancytab-btns button.active{background:var(--white-color); color: var(--primary-color);}
.fancytab.hide { display: none; }

/* Betslip */
.betslip-wraper{overflow: hidden; transition: max-height 0.5s ease; max-height: 0; position: relative;}
.betslip-wraper.open{max-height: 300px; overflow: visible;}
.betslip{background: rgb(114 187 239 / 50%); border-radius: 6px; border: 1px solid var(--border-color); padding:0; margin: 5px 0; width: 100%; max-width: 100%; position: relative;}
.betslip.lay{background: rgb(249 148 186 / 50%);}
.betslip .close-btn { position: absolute; right: -6px; top: -7px; background: rgb(187, 9, 9); height: 20px; width: 20px; color: var(--white-color); font-size: 25px; padding: 8px 5px; line-height: 0; border-radius: 20px; cursor: pointer; }
.betslip-top{border-radius: 0; justify-content: flex-end; align-items: flex-end; gap: 10px; margin-bottom: 6px; display: flex; background: var(--primary-color-w-opacity); padding:3px 10px 5px; border-top-left-radius: 6px; border-top-right-radius: 6px;}
.betslip .bet-selection-name{font-size: 14px; color: var(--text-color); font-weight: 500; position: relative; padding-top: 16px; padding-right: 6px;}
.betslip .bet-selection-name:after { content: '|'; position: absolute; right: -4px; bottom: 1px; }
.betslip .bet-profit { display: flex; align-items: center; font-size: 16px; color: var(--text-color); }
.betslip .betslip-odds, .betslip .betslip-stake{ flex-direction: column; gap: 0; line-height: 1; display: flex; align-items: center;}
.betslip .betslip-odds span{font-weight: 600; color: var(--betslip-elements); width: 80px; border: 1px solid var(--betslip-elements); border-radius: 0; font-size: 14px; height: 26px; display: flex; align-items: center; justify-content: center;}
.betslip .betslip-stake .input-group{ display: flex; }
.betslip .betslip-stake .input-group span{width: 26px; height: 26px; background: var(--betslip-elements); color: var(--white-color); font-size: 16px; display: flex; align-items: center; justify-content: center; cursor: pointer; }
.betslip .betslip-stake .input-group input{background: transparent; height: 26px; width: 80px; font-size: 14px; font-weight: 500; color: var(--betslip-elements); border-radius: 0; border: 1px solid var(--betslip-elements); text-align: center; padding: 0;}
.betslip .betslip-stake .input-group input::placeholder{font-size: 14px; font-weight: 500; color: var(--betslip-elements); border-radius: 0;}

.betslip-placebutton { display: flex; justify-content: flex-end; align-items: center; padding: 6px 10px;}
.betslip-placebutton .price-btns{ display: flex; align-items: center; gap: 5px; justify-content: flex-start; }
.betslip-placebutton .price-btns span { height: 27px; min-width: 52px; display: flex; align-items: center; justify-content: center; font-weight: 500; background: var(--primary-color-w-opacity); font-size: 13px; padding: 2px 5px; border-radius: 30px; cursor: pointer; border: 1px solid var(--betslip-elements); color: var(--betslip-elements);}
.betslip-placebutton .betplace-btn button{padding: 0 15px; font-weight: 600; font-size: 14px; height: 30px; margin-left: 5px; animation: glowingplace 1200ms infinite; background: var(--green-color); display: inline-flex; align-items: center; justify-content: center; color: var(--white-color); border-radius: 30px; text-transform: uppercase;}
@keyframes glowingplace {
    0% {
        opacity: 1;
    }
    50% {
    opacity: 0.5;
    }
    100% {
       opacity: 1;
    }
}

.sport-all-data.inner-match-list .match-info-row .market-limit { white-space: nowrap; line-height: 1; text-align: right; font-size: 16px; color: var(--primary-color); font-weight: 600; padding: 0 6px; margin-left: auto;}
.sport-all-data .match-data .data-with-betslip + .data-with-betslip { border-top: 1px solid var(--border-color); }
.disable{opacity: 0.7; pointer-events: none;}
.betlist table.betlist-table th, .betlist table.betlist-table td{text-align: center; padding: 5px; border: 0; background: transparent;}


/* Mobile Betslip */
.betslip_mobileview{display: none; padding: 5px; background: rgb(184, 221, 247); border-radius: 10px; position: relative; margin: 8px 5px 6px;}
.betslip_mobileview table.eventdetails td { padding: 0; border: 0;}
.betslip_mobileview .close-btn { position: absolute; right: -5px; top: -7px; background:#bb0909; height: 20px; width: 20px; color: var(--white-color); font-size: 25px; padding: 8px 5px; line-height: 0; border-radius: 20px; cursor: pointer; }
.betslip_mobileview table.eventdetails .plus-minus-wraper { display: flex; gap: 10px; padding: 0 20px; margin: 0 0 8px; justify-content: center;}
.betslip_mobileview table.eventdetails .plus-minus-wraper .plus-minus { display: flex; }
.betslip_mobileview table.eventdetails .plus-minus-wraper .plus-minus span { background: var(--primary-color) !important; border: 1px solid var(--primary-color); min-width: 20%; color: var(--white-color); font-size: 18px; font-weight: 700; display: flex; align-items: center; justify-content: center; }
.betslip_mobileview table.eventdetails .plus-minus-wraper .plus-minus input[type="text"] { font-weight: 600; font-size: 12px; line-height: normal; color: #000000; background: transparent; border: 1px solid var(--primary-color); border-radius: 0; text-align: center; letter-spacing: 0; width: 100%; height: 26px; margin: 0; padding: 5px; }
.betslip_mobileview table.eventdetails .mbet-slip{border: 1px solid var(--primary-color); background: var(--light-color2); }
.betslip_mobileview table.eventdetails .mbet-slip-wraper { display: table; width: 100%;}
.betslip_mobileview table.eventdetails .mbet-slip-wraper .odds-value { font-weight: 600; font-size: 12px; height: 30px; line-height: 22px; padding: 4px 1px; border-right: 1px solid var(--primary-color); color: var(--black-color); width: 12.5%; display: table-cell; text-align: center; }
.betslip_mobileview table.eventdetails .mbet-slip-wraper .odds-value.max{background: var(--lightblue-color);}
.betslip_mobileview table.eventdetails .mbet-slip-wraper .odds-value.all{background: var(--lightpink-color);}
.betslip_mobileview table.eventdetails .mbet-slip-wraper .odds-value:last-child{border-right:0;}
.betslip_mobileview table.eventdetails tr.numbering{border-top: 1px solid var(--border-color);}
.betslip_mobileview table.eventdetails tr.numbering td { width: 14%; height: 30px; font-weight: 600; font-size: 14px; line-height: 30px; text-align: center; vertical-align: middle; border: 1px solid var(--border-color); background: var(--light-color2);}
.betslip_mobileview table.eventdetails .btn-wraper { display: flex; }
.betslip_mobileview table.eventdetails .btn-wraper .btn { width: 100%; border-radius: 0; color: var(--white-color); cursor: pointer; font-weight: 600; font-size: 11px; height: auto; line-height: 17px; padding: 6.5px 10px; text-align: center;}
.betslip_mobileview table.eventdetails .btn-wraper .btn.cancel-bet { background: #c92233; background-color: #c30529; background-image: linear-gradient(#ff5071, #c30529); }
.betslip_mobileview table.eventdetails .btn-wraper .btn.place-bet{ background: var(--green-color); animation: glowingplace 1200ms infinite;}

.exchange-inner-page.content-w-sidebar, .exchange-page.content-w-sidebar { grid-template-columns: calc(100% - 450px - 15px) 450px; }
.sidebar-box .betlist { overflow: auto; border-radius: 15px;}
.fancy-popup h2 { font-size: 15px; padding: 10px 10px; background-color: var(--primary-color); border-radius: 30px; color: var(--white-color); text-align: center; margin-bottom: 10px; width: 100%; font-weight: 400; line-height: 1.2;}
.fancy-book-content table thead tr { background-color: var(--primary-color); color: var(--white-color); }
.fancy-book-content table tbody tr th{font-size: 16px; font-weight: 400;}
.fancy-book-content table tbody tr td{border-bottom: 1px solid var(--border-color); padding: 10px 5px; font-size: 16px; font-weight: 400; line-height: normal; text-align: center;}
.fancy-book-content table tbody tr:nth-child(even){background-color: #e7eaf6;}
.fancy-popup .modal-dialog { max-width: 520px; }
.fancy-popup .modal-content { padding: 15px; border-radius: 30px; }
.fancy-popup .modal-header { padding: 0; border: 0; margin: 0; }
.fancy-popup .modal-body { padding: 10px; }
.fancy-popup .close-btn { position: absolute; text-align: center; bottom: -90px; left: 0; width: 100%; padding-bottom: 15px; }
.fancy-popup .close-btn button.btn { font-weight: 400; }
button.btn.fancy-book-btn { padding: 2px 15px 3px; font-size: 12px; line-height: 1; margin-left: 4px; font-weight: 400;}
.cashout-btn { margin-right: auto; }
.cashout-btn .btn { padding: 2px 10px; font-size: 12px; margin-left: 10px; background: var(--yellow-color); color: var(--primary-color); font-weight: 400; line-height: 1; border-radius: 20px; }

.title.live-stream-title{display: flex; align-items: center; justify-content: space-between; cursor: pointer;}
.title.live-stream-title:after{content: "\f107"; font-family: "Font Awesome 6 Free"; font-weight: 600; vertical-align: middle; margin-left: 15px;}
.livestream-iframe { overflow: hidden; transition: max-height 0.5s ease; max-height: 0;}
.livestream-iframe iframe{ width: 100%; overflow: hidden !important; overflow-y: hidden !important; aspect-ratio: 1 / 0.565; vertical-align: middle; border-radius: 20px; border: 0; background: #000000;}
.livestream-iframe.open { max-height: 700px; }
.book-value{margin-left: auto;}


.match-not-found{ height: 100vh; display: flex; align-items: center; flex-direction: column; justify-content: center; text-align: center;position: fixed; left: 0; top: 0; width: 100%; z-index: 111111111; background: var(--light-color);}
.match-not-found.hide{display: none;}
.match-not-found h2 { margin: 0; font-size: 45px; text-align: center; padding: 0 10px;}
.match-not-found h2 span::before { content: ''; height: 160px; width: 160px; background: url(../../assets/images/error-crying-face.svg) no-repeat center, linear-gradient(15deg, var(--primary-color) 40%, var(--secondary-color) 60%, var(--light-color) 1000%) repeat; color: var(--white-color); display: table; background-size: 100px; margin: auto; border-radius: 50%; animation: error404anim infinite 2s; transform: scale(1);}
.match-not-found p { margin: 10px 0 40px; font-size: 20px; }
.match-not-found .error-404-btn a { display: inline-block; text-decoration: none; background: var(--primary-color); color: var(--white-color); padding: 11px 15px 12px; min-width: 110px; text-align: center; border-radius: 5px; font-size: 20px; border: 1px solid var(--primary-color); margin: 0 5px; transition: 0.5s;}
.match-not-found .error-404-btn a:hover{background: transparent; color: var(--primary-color);}
.match-not-found .error-404-btn a:nth-child(2) { background: transparent; color: var(--primary-color); }
.match-not-found .error-404-btn a:nth-child(2):hover{background: var(--primary-color); color: var(--white-color);}

@keyframes error404anim {
0%{transform: scale(1.03);}
50%{transform: scale(1);}
100%{transform: scale(1.03);}
}

/* 7-2-24 */
.sport-all-data.lottery-list .match-odds .box span { width: 30px; height: 30px; }

@media (max-width:1800px) {
    .exchange-inner-page.content-w-sidebar, .exchange-page.content-w-sidebar { grid-template-columns: calc(100% - 400px - 15px) 400px; }
}
@media (max-width:1600px) {
    .exchange-inner-page.content-w-sidebar, .exchange-page.content-w-sidebar { grid-template-columns: calc(100% - 350px - 15px) 350px; }
}
@media (max-width:1450px) {
    .exchange-inner-page.content-w-sidebar, .exchange-page.content-w-sidebar { grid-template-columns: calc(100% - 300px - 15px) 300px; }
    .inner-match-list.sport-all-data .match-data .match-info-row .match-name{min-width: auto; max-width: 300px;}
}
@media (max-width:1280px) {
    .exchange-inner-page.content-w-sidebar, .exchange-page.content-w-sidebar { grid-template-columns: calc(100% - 250px - 15px) 250px; }
}
@media (max-width:1150px) {
    .inner-match-list.sport-all-data .match-data .match-info-row .match-name{min-width: auto; max-width: 300px;}
}
@media (max-width:999px) {
    .exchange-inner-page.content-w-sidebar, .exchange-page.content-w-sidebar { grid-template-columns: calc(100% - 200px - 15px) 200px; gap: 15px; }
    /* 7-2-25 */
    .sport-all-data.lottery-list .match-data .match-list  .match-info-row{ display: grid; grid-template-columns: auto auto; }
    .sport-all-data.lottery-list .match-odds{grid-column: 1 / -1; max-width: 100%;}
    .sport-all-data.lottery-list .match-odds .box{flex: auto;}
    .sport-all-data.lottery-list .match-odds .box span {width: 100%;}
}
@media (max-width:767px) {
    .betslip{display: none;}
    .betslip_mobileview{display: block;}
    .betslip_mobileview.lay{background: rgb(252, 201, 220);}
    .exchange-inner-page.content-w-sidebar, .exchange-page.content-w-sidebar { grid-template-columns: 100%; gap: 0; }
    .inner-match-list.sport-all-data .match-data .match-info-row { flex-direction: row; }
    .inner-match-list.sport-all-data .match-odds .box span:not(:last-child, .pink), .inner-match-list.sport-all-data .match-odds .box span.pink:not(:first-child) { display: none; }
    .inner-match-list.sport-all-data .heading .odds-heading, .inner-match-list.sport-all-data .heading .odds-heading.col2-odds-heading { display: flex; width: 106px; }
    .inner-match-list.sport-all-data .match-odds{min-width: 106px; max-width: 106px;}
    .inner-match-list.sport-all-data .match-data .match-info-row .match-name, .inner-match-list.sport-all-data .match-heading{font-size: 14px;}
    .sport-all-data.inner-match-list .match-info-row .market-limit{font-size: 12px;display: flex; flex-direction: column; margin-left: auto;}
    .sport-all-data.inner-match-list.fancylist .fancytabs { overflow: hidden; }
    .sport-all-data.inner-match-list.fancylist .match-wrapper{overflow: hidden;}
    .fancy-popup .modal-body{padding: 0;}
    .match-not-found h2 span::before{    height: 130px; width: 130px; background-size: 80px;}
    .match-not-found h2 { font-size: 28px;}
    .match-not-found p{font-size: 16px; margin: 10px 0 30px;}
    .match-not-found .error-404-btn a{font-size: 16px;padding: 8px 15px 9px; min-width: 85px;}
    /* 7-2-25 */
    .sport-all-data.lottery-list .match-odds{max-width: 100%;}
}
