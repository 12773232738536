.live-score { padding: 10px 10px; background-color: var(--white-color); border: 1px solid var(--border-color); border-radius: 30px; margin: 0 0 10px; display: flex; flex-wrap: nowrap; flex-direction: column;}
.live-score .heading { background: var(--primary-gradient); border-radius: 30px; display: flex; justify-content: space-between; padding:5px 10px; width: 100%; line-height: 1.2;}
.live-score .heading .heading-text { color: var(--white-color); font-weight: 600; display: flex; align-items: center; gap: 6px;}
.live-score .heading .heading-text.time { font-weight: 300; }
.live-score .heading .heading-text .fa-clock{ font-size: 14px; vertical-align: middle; line-height: 1;}
.live-score .scoreboard{margin-top: 10px;}
.live-score .scoreboard iframe{width: 100%; border: 0; outline: 0; vertical-align: middle; background: #ddd; border-radius: 20px;}

/* Mobile live stream tab */
.live-stream-mobile-tab{display: none; margin: 5px 0 0;}
.live-stream-mobile-tab button.btn { background: transparent; border: 0; color: var(--text-color); padding: 3px 16px; border-radius: 30px; font-size: 12px; font-weight: 400; }
.live-stream-mobile-tab button.btn.active { background: var(--primary-gradient); color: #fff; }
.live-stream-mobile-tab .live-stream-tab-btns { display: flex; justify-content: space-between; background: var(--light-color); border-radius: 30px; margin: 0 0 5px; }
.live-stream-mobile-tab .live-stream-tab-content .live-stream-tab-panel:not(.active) { display: none; }
.live-stream-mobile-tab .live-stream-tab-content .live-stream-tab-panel iframe { border-radius: 10px; vertical-align: middle; border: 0; width: 100%; }
.live-stream-mobile-tab .title{ display: flex; align-items: center; justify-content: space-between; cursor: pointer; background: var(--primary-color); color: var(--white-color); border-radius: 15px; padding: 5px 10px; font-size: 15px; line-height: 1.2; margin: 0 0 5px; }

@media (max-width:767px) {
    .live-stream-mobile-tab{display: block;}
    .live-score .scoreboard, .live-score .heading .heading-text span.series-name, .live-score .heading .heading-text span.seprator { display: none; }
    .live-score .heading{ flex-wrap: wrap;}
}