header{width: 100%; position: sticky; top: 0; z-index: 9999; background: var(--light-color);}
header .main-menu { display: flex; justify-content: space-between; align-items: center; background: var(--header-bg); padding: 11px 15px; margin: 0 -15px; }
header .logo{display: flex; align-items:center; padding-right: 6px;}
header .logo img{max-width: 160px; width: 100%;}
header .menu { border-radius: 90px; background: rgba(255, 255, 255, 0.20); }
header .menu-wrap {gap: 10px; display: flex; align-items: center; padding: 5px 10px;}
header .menu .menu-links { display: inline-block; color: var(--white-color); transition: all 0.3s ease-in-out; font-size: 15px; border-radius: 30px; background-color: transparent; padding: 2px 5px; font-weight: 400; line-height: 1.5; cursor: pointer;}
header .menu .menu-links:hover, header .menu .menu-links.active {transition: all 0.3s ease-in-out; border-radius: 30px; background-color: var(--white-color); color: var(--text-color); font-weight: 600;}
header .account-btns { display: flex; align-items: center; gap: 5px; }
header .account-btns a.btn, header .account-btns button { display: flex; align-items: center; justify-content: center; width: fit-content; background: var(--white-color); color: var(--text-color); border-radius: 30px; font-weight: 600; transition: all 0.3s ease-in-out; font-size: 15px; cursor: pointer; padding: 8px 8px; border: 0; line-height: 1; text-transform: uppercase;}
header .account-btns a.btn:hover, header .account-btns button:hover {background: var(--primary-color); color: var(--white-color);}
header .account-btns .dropdown .btn{background: var(--white-color); border:1px solid var(--white-color); color: var(--text-color); padding: 5px; line-height: 1.5;}
header .account-btns .dropdown .btn.logout-btn { margin: auto; color: var(--primary-color); margin-top: 10px; }
header .account-btns .dropdown .btn-header-username{font-weight: 400; text-transform: capitalize;}
header .natification-bar{ padding: 0 10px; background-color: var(--primary-light); color: var(--notification-text); margin: 0 -15px; position: relative; padding-left: 110px; font-size: 15px;}
header .natification-bar button { position: absolute; top: 0; left: 0; padding: 0 15px; height: 100%; background: var(--yellow-color); border: 0; z-index: 1; text-transform: uppercase; font-weight: 400; color: var(--notification-text); display: flex; align-items: center; font-size: 15px; gap: 7px; font-family: var(--primary-font); }
header .account-btns .dropdown .dropdown-menu ul li span{cursor: pointer;}
header .account-btns .dropdown .dropdown-menu ul li span.active{color: var(--primary-color);}

.wallet-bal { cursor: pointer; display: inline-flex; align-items: center; gap: 0px; width: max-content; min-width: 70px; font-size: 13px; padding: 5px 12px; border-radius: 15px; background-color: #32962a; color: var(--white-color); flex-direction: column; position: relative; line-height: 1.2; font-weight: 400;}
header .account-btns button.my-bet-btn { background: var(--yellow-color); color: var(--text-color); border-radius: 50px; font-size: 15px; padding: 6px 15px; min-height: 37px; display: flex; align-items: center; font-weight: 400; text-transform: capitalize;}


.latest-notifiction-popup .modal-header h2{margin: 0; color: var(--primary-color);font-size: 24px; font-weight: 400;}
.latest-notifiction-popup .modal-header h2 i{margin-right: 6px;}
.latest-notifiction-popup .modal-header button{border: 0; background: transparent;}
.latest-notifiction-popup .modal-dialog{max-width: 1000px; width: 96%;}
.notification-list .box + .box{margin-top: 14px;}
.notification-list .box .notification-header{background: var(--primary-color); color: var(--white-color); padding: 10px 15px; border-top-left-radius: 6px; border-top-right-radius: 6px; font-size: 15px; }
.notification-list .notification-content { display: grid; gap: 20px; border-bottom-left-radius: 6px; border-bottom-right-radius: 6px; border: 1px solid #ddd; border-top: 0; grid-template-columns: 110px auto; align-items: center;}
.notification-list .notification-content .date-titme { white-space: nowrap; opacity: 0.6; border-right: 1px solid #ddd; padding: 10px 15px; height: 100%; display: flex; align-items: center; flex-direction: column; justify-content: center; }
.notification-list .notification-content .date-titme p { margin: 0; font-size: 15px; }
.notification-list .notification-content .conent{padding:10px 15px 10px 0;}
.notification-list .notification-content .conent p{margin: 0;font-size: 15px;}
.notification-list .notification-content .conent p+p{margin-top: 15px; opacity: 0.6;}

@media (max-width:1440px) {
    header .account-btns .dropdown .btn{gap: 3px; font-size: 14px;}
    .wallet-bal{font-size: 12px;}
    header .menu .menu-links{font-size: 14px;}
}
@media (max-width:1280px) {
    .dropdown .btn .profile-img, .dropdown .btn svg { width: 22px; }
    header .menu-wrap{gap: 7px;}
    header .menu .menu-links{font-size: 13px;}
}
@media (max-width:1150px) {
    header .main-menu { padding: 5px 15px; margin: 0 -15px; }
    header .menu-wrap{gap: 5px;}
    header .menu .menu-links{font-size: 12px;}
    header .logo img{ max-width: 100px; }
    header .account-btns a.btn, header .account-btns button{font-size: 13px;}
    header .account-btns button.my-bet-btn{font-size: 12px; min-height: 35px;}
    .wallet-bal{font-size: 10px;}
    header .account-btns .dropdown .btn { gap: 2px;}
    .dropdown .btn .profile-img, .dropdown .btn svg { width: 20px; }
}
@media (max-width:999px) {
    header .logo img{ max-width: 130px; }
    header .main-menu { padding: 6px 15px 0; flex-wrap: wrap; }
    header .menu { order: 3; width: calc(100% + 30px); margin: 6px -15px 0; border-radius: 0; overflow-x: auto; white-space: nowrap; gap: 6px; display: flex;}
    header .menu .menu-wrap {white-space: nowrap; gap: 6px; margin: auto;}
    header .menu .menu-links{font-size: 14px;}
    header .account-btns a { font-size: 13px; padding: 5px 8px; }
}
@media (max-width:767px) {
    header .logo img{ max-width: 110px;}
    header .main-menu { padding: 6px 15px 0; flex-wrap: wrap; }
    header .menu .menu-wrap {white-space: nowrap; gap: 6px; margin: auto;}
    header .menu .menu-links{font-size: 14px;}
    header .account-btns a { font-size: 13px; padding: 5px 8px; }
    header .account-btns button.my-bet-btn{padding: 5px 10px;}
    .wallet-bal { font-size: 9px; line-height: 10px; font-weight: 400; }
    .dropdown .btn .profile-img, .dropdown .btn svg { width: 18px; }
}
@media (max-width:480px) {
    header .main-menu { padding: 6px 10px 0; }
    header .main-menu, header .natification-bar{margin: 0 -5px;}
    header .menu { width: auto; margin: 6px -10px 0; }
    header .menu .menu-links { font-size: 13px; padding: 2px; }
    header .account-btns .dropdown .btn { padding: 5px 5px; gap: 2px; font-size: 11px; }
    header .natification-bar{font-size: 13px; padding-left: 85px;}
    header .natification-bar button{font-size: 12px; padding: 0 10px;}
    header .account-btns button.my-bet-btn { padding: 3px 6px; min-height: 28px; }
    header .account-btns .dropdown .btn-header-username{display: none;}
}