.user-profile { display: grid; grid-template-columns: auto 66.66666667%; gap: 10px; padding-top: 10px;}
.user-profile .box{padding: 10px 10px; background-color: var(--white-color); border: 1px solid var(--border-color); border-radius: 30px;}
.user-profile .box + .box{margin-top: 10px;}
.user-profile .box .title { display: flex; align-items: center; flex-wrap: wrap; background: var(--primary-gradient); padding: 10px 10px; border-radius: 30px; color: var(--white-color); font-size: 16px; margin-bottom: 10px; line-height: 1.2;}
.user-profile .box p{margin: 0; font-size: 13px; text-align: center;}
.user-profile .box p + p{margin-top: 15px;}
.user-profile .user-icon { height: 60px; width: 60px; border-radius: 50%; overflow: hidden; margin: 0 auto; margin-bottom: 5px; background: var(--primary-color); }
.user-profile .username { text-align: center; font-size: 16px; margin: 0 0 10px; font-weight: 500;}
.user-profile .user-info { display: flex; align-items: center; justify-content: space-between; padding: 10px 0px; flex-wrap: wrap; }
.user-profile .change-psw-link{text-align: center;}
.user-profile .change-psw-link a{ text-decoration: none; color: var(--primary-color); font-size: 15px; font-weight: 600;}
.user-profile .profit-box-row { display: flex; gap: 10px; }
.user-profile .profit-box-row + .profit-box-row{margin-top: 10px;}
.user-profile .profit-box { background-color: var(--light-color2); text-align: center; height: 120px; display: flex; flex-direction: column; align-items: center; justify-content: center; border-radius: 30px; flex: 1;}
.user-profile .profit-box p { font-size: 16px; margin: 0;}
.user-profile .profit-box p strong { display: block; color: var(--primary-color); margin: 10px 0 0; }

@media (max-width:999px) {
    .user-profile { grid-template-columns: auto 56.666667%; }
}
@media (max-width:767px) {
    .user-profile { grid-template-columns: 100%; }
}
@media (max-width:480px) {
    .user-profile .profit-box p { font-size: 14px; padding: 0 5px; line-height: 1; }
}