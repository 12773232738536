.sport-all-data{padding: 5px; background-color: var(--white-color); border: 1px solid var(--border-color); border-radius: 20px; margin: 0 0 10px; display: flex; flex-wrap: wrap; overflow: auto;}
.sport-all-data .match-wrapper{ flex: 1 1 calc(50% - 20px); }
.sport-all-data .heading { background: var(--primary-gradient); border-radius: 30px; display: flex; justify-content: space-between; padding:3px 10px; flex-wrap: wrap;}
.sport-all-data .heading .odds-heading { display: flex; justify-content: space-around; width: 392px;}
.sport-all-data .heading .heading-text {color: var(--white-color); font-weight: 600; display: flex; align-items: center; gap: 5px;}
.sport-all-data .match-data .series-title{background-color: var(--primary-light); color: var(--primary-color); text-align: left; padding: 3px 10px 3px; margin: 4px -5px 4px; border-radius: 0; font-weight: 600; position: relative; font-size: 14px; line-height: 1;}
.sport-all-data .match-data .match-info-row{display: flex; justify-content: space-between; align-items: center; padding: 3px 0; gap:5px; line-height: 1;}
.sport-all-data .match-data .match-info-row:hover{background: var(--light-color);}
.sport-all-data .match-data .match-info-row + .match-info-row{border-top: 1px solid var(--border-color);}
.sport-all-data .match-data .match-info-row .match-name{font-size: 15px; display: flex; align-items: center; gap: 5px; width: 100%;}
.sport-all-data .match-data .match-info-row .match-date{font-size: 13px; border: 1px solid var(--primary-color); border-radius: 10px; padding: 4px 8px; font-weight: 400; line-height: 12px; white-space: nowrap; color: var(--primary-color); display: inline-block;     margin-right: 5px;}
.sport-all-data .match-data .match-info-row .match-live-status{ color: var(--white-color); cursor: pointer; display: inline-block; font-size: 11px; padding: 3.5px 15px; text-align: center; font-weight: 400; line-height: 1; text-transform: uppercase; background-color: #32962a; border: 1px solid #32962a; border-radius: 60px; animation: glowing 1200ms infinite; transition: 0.5s; display: none;}
@keyframes glowing {
    0% {
    background-color: #32962a;
    color: var(--white-color);
    }
    50% {
    background-color: transparent;
    color: #32962a;
    }
    100% {
    background-color: #32962a;
    color: var(--white-color);
    }
}
.sport-all-data .match-data .match-info-row .match-update { display: flex; gap: 6px; align-items: center; justify-content: space-between; }
.sport-all-data .match-data .match-info-row .match-update .match-live-status{display: none !important;}
.sport-all-data .match-data .match-info-row .match-stream { display: flex; gap: 0 5px; margin-left: auto; align-items: end;}
.sport-all-data .match-data .match-info-row .match-stream .box{ display: flex; flex-direction: column; line-height: 1; text-align: center; font-size: 12px;}
.sport-all-data .match-data .match-info-row .match-stream .box .blue-bg{background: var(--primary-color); color: var(--white-color); border-radius: 4px; padding: 2px 4px; text-transform:uppercase;}
.match-odds { display: flex; justify-content: space-between; position: relative; gap: 4px;}
.match-odds .suspended { background: #000000b0; position: absolute; left: 0; top: 0; width: 100%; height: 100%; border-radius: 8px; color: red; text-align: center; padding: 7px; text-transform: uppercase; font-weight: 600; z-index: 1; display: flex; align-items: center; justify-content: center; line-height: 1.5; font-size: 15px;}
.match-odds .box{ display: flex; gap: 4px;}
.match-odds .box span{display: inline-flex; align-items: center; justify-content: center; height: 36px; width: 62px; border-radius: 5px; font-weight: 600; font-size: 18px; flex-direction: column; vertical-align: top; line-height: 1; background-color: var(--lightblue-color);}
.match-odds .box span.lock{pointer-events: none;}
.match-odds .box span small{font-size: 11px; font-weight: 400;}
.match-odds .box span .fa-lock{font-size: 90%; opacity: 0.7; pointer-events:none;}
.match-odds .box span.pink{background-color: var(--lightpink-color);}

.short-score{display: flex; min-width: 62px; max-width: 62px; height: 28px; border-radius: 3px; overflow: hidden; position: relative;}
.short-score .block{flex:1; display: flex; flex-direction: column; justify-content: center; height: 100%; width: 50%;}
.short-score .block span{color: var(--white-color);font-size: 12px; font-weight: 600; line-height: 1; padding: 1px; text-align: center; animation: inplayTxtAnim 2s linear infinite; animation-delay: 1s;}
.short-score .block.left{background-color: #45ba77;}
.short-score .block.right{background-color: #20a855;}


/* Animation Inplay */
@keyframes inplayTxtAnim {
    0% { opacity: 0.2; }
    50% { opacity: 1; }
    100% { opacity: 0.2; }
}

@keyframes animate1 {
    0% { transform: translateX(-100%); }
    100% { transform: translateX(100%); }
}

  @keyframes animate2 {
    0% { transform: translateY(-100%); }
    100% { transform: translateY(100%); }
  }

  @keyframes animate3 {
    0% { transform: translateX(100%); }
    100% { transform: translateX(-100%); }
  }

  @keyframes animate4 {
    0% { transform: translateY(100%); }
    100% { transform: translateY(-100%); }
  }
.short-score .animBorder { overflow: hidden; position: absolute; left: 0; top: 0; height: 100%; width: 100%; }
.short-score .animBorder span:nth-child(1) { position: absolute; top: 0; right: 0; width: 100%; height: 3px; background: linear-gradient(to right,#45ba77, red); animation: animate1 2s linear infinite; }
.short-score  .animBorder span:nth-child(2) { position: absolute; top: 0; right: 0; height: 100%; width: 3px; background: linear-gradient(to bottom,#45ba77, red); animation: animate2 2s linear infinite; animation-delay: 1s; }
.short-score .animBorder span:nth-child(3) { position: absolute; bottom: 0; right: 0; width: 100%; height: 3px; background: linear-gradient(to left,#45ba77, red); animation: animate3 2s linear infinite; }
.short-score .animBorder span:nth-child(4) { position: absolute; top: 0; left: 0; height: 100%; width: 3px; background: linear-gradient(to top,#45ba77, red); animation: animate4 2s linear infinite; animation-delay: 1s; }


@media (max-width:1280px) {
    .sport-all-data .match-data .match-info-row .match-stream .box{font-size: 11px;}
    .sport-all-data .match-data .match-info-row .match-name{font-size: 14px;}
}
@media (max-width:1150px) {
    .sport-all-data{overflow: auto;}
    .sport-all-data .match-data .match-info-row .match-name{font-size: 14px; min-width: 400px;}
}
@media (max-width:767px) {
  .match-odds, .match-odds .box{gap: 3px;}
    .match-odds .box span {font-size: 17px; padding: 0 2px; width: 50px; height: 34px;}
    .sport-all-data .heading .odds-heading{display: none;}
    .sport-all-data .match-data .match-info-row{flex-direction: column;}
    .sport-all-data .match-data .match-info-row .match-update{width: 100%;}
    .sport-all-data .match-data .match-info-row .match-name{width: 100%; font-size: 13px; min-width: auto;}
    .match-odds{width: 100%;}
    .sport-all-data .match-data .match-info-row .match-name .match-live-status{display: none;}
    .sport-all-data .match-data .match-info-row .match-update .match-live-status{display: inline-block;}
    .sport-all-data .match-data .match-info-row .match-date{font-size: 12px; margin-right: 0;}
    .match-odds .suspended{font-size: 13px;}

    /* 29-1-25 */
    .match-odds .box{flex: 1;}
    .match-odds .box span{width: 100%;}
}
