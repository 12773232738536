.menu-dropdown-popup h2 { margin-bottom: 0px; color: var(--primary-color); font-weight: 600; font-size: 20px; position: relative; text-transform: uppercase; display: flex; align-items: center; gap: 10px; line-height: 1;}
.menu-dropdown-popup h2 .title-v-border { height: 15px; width: 8px; display: flex; align-items: center; justify-content: center; background-color: var(--primary-color); }
.menu-dropdown-popup button.btn-close{background: transparent;}
.menu-dropdown-popup button.btn-close:after { font-weight: 400; font-family: "Font Awesome 6 Free"; content: "\f057"; font-size: 20px; color: var(--primary-color);}
.menu-dropdown-popup .btn-sec{ margin-top: 20px; }
.menu-dropdown-popup .btn-sec .btn { width: 100%; font-weight: 400; }
.menu-dropdown-popup .modal-content{border-radius: 30px;}
.menu-dropdown-popup .modal-header{border-bottom: 0;}
.menu-dropdown-popup .input-box input:not(input[type="checkbox"], input[type="radio"]) { font-size: 13px; color: var(--text-color); border-radius: 30px; border: 1px solid var(--primary-color); background-color: var(--white-color); height: auto; padding: 6px 15px;}
.menu-dropdown-popup label { font-size: 15px; margin: 0px; font-weight: 400;}
.menu-dropdown-popup .input-box { margin: 0 0 10px; }
.menu-dropdown-popup .input-box .input-w-icon { position: relative; }
.menu-dropdown-popup .input-box .input-w-icon span.input-icon { position: absolute; right: 8px; top: 5px; order: none; background-color: transparent; font-size: 13px; color: var(--text-color);}

.on-off-btn-section{display: inline-block;}
.on-off-btn-section span { font-weight: 400; font-size: 14px; line-height: normal; color: var(--text-color); float: left; }
.on-off-btn-section span.on-off { display: flex; flex-direction: row-reverse; flex-wrap: nowrap; align-items: center; align-content: center; line-height: 0; }
.on-off-btn-section span.on-off label.switch { position: relative; width: 46px; height: 22px; background: var(--border-color); border-radius: 50px; display: inline-block; padding: 0; margin: 0 10px; line-height: 0; cursor: pointer; vertical-align: middle; }
.on-off-btn-section span.on-off label.switch input[type="checkbox"] { position: absolute; top: 0; left: 0; opacity: 0; visibility: hidden; padding: 0; margin: 0; }
.on-off-btn-section span.on-off label.switch span.slider { position: absolute; top: 50%; -ms-transform: translateY(-50%); -webkit-transform: translateY(-50%); transform: translateY(-50%); right: 4px; width: 18px; height: 18px; border-radius: 50%; background: white; display: inline-block; }
.on-off-btn-section span.on-off label.switch input:checked + span.slider { left: 4px; }
.on-off-btn-section span.on-off label.switch.on{ background: var(--primary-color); }

.one-click-popup .input-box { display: flex; gap: 8px; align-items: center; }
.menu-dropdown-popup.one-click-popup .bet-value-add .input-box input { border-radius: 6px; }
.one-click-popup .bet-slip-btn { display: flex; align-items: center; gap: 5px; justify-content: flex-start; overflow: auto; }
.one-click-popup .bet-slip-btn span { height: 27px; min-width: 52px; display: flex; align-items: center; justify-content: center; font-weight: 400; background-color: var(--light-color2); font-size: 13px; padding: 2px 5px; border-radius: 30px; cursor: pointer; border: 1px solid var(--primary-color); color: var(--primary-color);}

.menu-dropdown-popup .change-psw-wraper .input-box label{padding-left: 5px;}
.menu-dropdown-popup .stack-change-wraper label{font-weight: 500; padding-left: 5px; font-size: 16px; margin: 0 0 3px;}