.service-box-sec .row { display: flex; margin: 10px -5px 0; flex-wrap: nowrap;}
.service-box-sec .row .col{width: 33.33%; padding: 0 5px;}
.service-box-sec .row .col .service-box { border-radius: 30px; background-color: var(--white-color); border: 1px solid var(--light-color); padding: 2em 2em; text-align: center; height: 100%;}
.service-box-sec .row .col .service-box .icon{ display: inline-flex; align-items: center; justify-content: center; padding: 10px; border-radius: 80px; background: var(--primary-light); }
.service-box-sec .row .col .service-box .icon svg{ height: 35px; width: 35px; fill: var(--primary-color);}
.service-box-sec .row .col .service-box .icon svg path{fill: var(--primary-color);}
.service-box-sec .row .col .service-box h2{ font-size: 20px; color: var(--primary-color); font-weight: 600; margin-bottom: .5rem; line-height: 1.2;}

@media (max-width:767px) {
    .service-box-sec .row .col .service-box h2{font-size: 16px;}
}
@media (max-width:767px) {
    .service-box-sec .row .col{width: 100%;}
    .service-box-sec .row .col .service-box h2{font-size: 12px;}
    .service-box-sec .row .col .service-box{padding: 10px; border-radius: 10px;}
    .service-box-sec .row .col .service-box .icon svg{width: 22px; height: 22px;}
}
@media (max-width:480px) {
    .service-box-sec .row .col .service-box h2{font-size: 10px; margin: 2px 0 0;}
    .service-box-sec .row .col .service-box{padding: 5px;}
    .service-box-sec .row .col { padding: 0 2px; }
}