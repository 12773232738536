
@media (max-width:1600px) {
.allsport-tab{gap: 8px;}
.allsport-tab .tab-btn { padding: 6px 15px; font-size: 14px;}
}
@media (max-width:1450px) {
.content-w-sidebar .sidebar .sidebar-box .title{font-size: 17px;}
}
@media (max-width:1280px) {
}

@media (max-width:1150px) {
.content-w-sidebar .sidebar { border-radius: 15px; }
.content-w-sidebar .sidebar .sidebar-box .title{padding:5px 10px; font-size: 15px;}
.content-w-sidebar .sidebar .sidebar-box .sidebar-card a + a { margin-top: 7px; }

/* Inplay Tab */
.allsport-tab{gap: 6px;}
.allsport-tab .tab-btn { padding: 6px 10px; font-size: 12px;}
.allsport-tab .tab-btn img { height: 16px; }
}
@media (max-width:999px) {
body{font-size: 14px;}
.btn{padding: 6px 20px; font-size: 14px;}
.no-data{font-size: 18px;}

/* Mobile Sport tab */
.mobile-sport-menu{display: block;}
.allsport-tab{display: none;}

/* 23-1-25 */
.sticky-link { bottom: 55px;}


}
@media (max-width:767px) {
.sidebar{display: none !important;}
.content-w-sidebar{grid-template-columns: 100%;}
.content-w-sidebar .banner img{border-radius: 15px;}
.no-data{font-size: 16px;}
/* multimarket-page */
.multimarket-page .sport-all-data .heading { flex-wrap: wrap; line-height: 1; gap: 4px; font-size: 12px; margin-bottom: 5px;}
.multimarket-page .exchange-inner-page.content-w-sidebar .sidebar { display: block !important; order: 1; position: static;}
.multimarket-page .exchange-inner-page.content-w-sidebar .left-content{order: 2;}
.multimarket-page .exchange-inner-page.content-w-sidebar .sidebar .sidebar-box .betlist{max-height: 300px;}
.multimarket-page .sport-all-data .heading .heading-text { display: block; }

.match-odds.time-tag { justify-content: flex-start; }

/* Inplay Filter */
.filter-btn-wrapper .filter-btn select { font-size: 12px; width: 110px; }
.filter-btn-wrapper .filter-btn span{font-size: 12px;}

/* Inplay Tab */
.allsport-tab{gap: 5px;}
.allsport-tab .tab-btn { border-top-left-radius: 10px; border-top-right-radius: 10px; padding: 6px 15px;}

/* 20-1-25 */
.sport-all-data .heading .odds-heading { gap: 1.5px; }
.inner-match-list.sport-all-data .match-heading .odds-heading .heading-text{font-size: 14px; max-width: 51px; margin-left: 1px; min-height: 22px;}
.oneclick-bt {padding: 8px 16px;font-size: 12px;font-weight: 500;}
.diposit-bt {padding: 8px 16px;font-size: 12px;font-weight: 500;}
}
@media (max-width:480px) {
.container-fluid { padding-left: 5px; padding-right: 5px; }
.row{margin-left: -5px; margin-right: -5px;}
.btn { padding: 6px 15px; font-size: 13px; }
/* Hourse Racing Popover  */
.match-info .title-w-popover .popover { left: 40px; top: 38px; }
.match-info .title-w-popover .popover:after { left: 20px; top: -5px;}

/* Mobile Sport tab */
.mobile-sport-menu { margin: 0 -5px; padding: 0 10px;}
.mobile-sport-menu ul li .mobile-tab-btn{font-size: 12px; padding: 8px 3px 4px;}
.mobile-sport-menu ul li .icon { width: 28px; height: 28px;}
/* .oneclick-bt {padding: 8px 12px;font-size: 10px;font-weight: 400; background: url(../images/one-click-bet-icon.png);} */
.oneclick-bt { padding: 0; font-size: 0; font-weight: 400; background: url(../images/one-click-bet-icon.png); height: 30px; width: 60px; height: 30px; background-size: contain; background-repeat: no-repeat; background-color: #f49d1d; background-position: center;}
.diposit-bt { padding: 0; font-size: 0; font-weight: 400; background: url(../images/diposit_icon.png); height: 30px; width: 60px; height: 30px; background-size: contain; background-repeat: no-repeat; background-color: #f49d1d; background-position: center;}

 /* Hourse Racing Popover  */
.match-info-row .title-w-popover .popover{left: calc(100% - 70px);}
}