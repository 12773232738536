.home-tab{margin: 10px 0 0;}
.home-tab .tab-header{ display: flex; justify-content: space-between; list-style: none; margin: 0; padding: 0; }
.home-tab .tab-header button { position: relative; font-weight: 600; font-size: 16px; line-height: normal; color: var(--white-color); background: var(--primary-gradient); cursor: pointer; text-align: center; -webkit-transition: 0.1s ease-in-out; transition: 0.1s ease-in-out; display: flex; flex: auto; align-items: center; justify-content: center; padding: 10px; margin: 0; }
.home-tab .tab-header button svg, .home-tab .tab-header button img{margin-right: 8px; width: 20px; filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(246deg) brightness(106%) contrast(102%);}
.home-tab .tab-header button.active svg, .home-tab .tab-header button.active img{filter: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(0%) hue-rotate(304deg) brightness(100%) contrast(102%);}
.home-tab .tab-header button.active { position: relative; color: var(--text-color); background: var(--white-color); z-index: 1; -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2); -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2); box-shadow: 0px 0 10px 0px rgba(0, 0, 0, 0.1); }
.home-tab .tab-header button:first-child { border-top-left-radius: 30px; }
.home-tab .tab-header button:last-child { border-top-right-radius: 30px; }

.home-tab .tab-content{ position: relative; background-color: var(--white-color); border-top: none; border-radius: 0 0 30px 30px; padding: 20px; -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2); -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2); box-shadow: 0 10px 20px 0px rgba(0, 0, 0, 0.1); z-index: 9; overflow: hidden}
.home-tab .tab-content .tab-panel{transform: translateY(-100%); transition: 0.5s; position: absolute; top: 0; left: 0; width: 100%; height: 420px; overflow: hidden; overflow-y: auto; }
.home-tab .tab-content .tab-panel.active{transform: translateY(0%); position: relative;}
.home-tab .sport-name{visibility: hidden; opacity: 0; pointer-events: none;}
.home-tab .tab-content .tab-panel::-webkit-scrollbar { width: 5px; }
.home-tab .tab-content .tab-panel::-webkit-scrollbar-track { background: var(--border-color); }
.home-tab .tab-content .tab-panel::-webkit-scrollbar-thumb { background: var(--text-color); }
.home-tab .tab-content .tab-panel::-webkit-scrollbar-thumb:hover { background: var(--text-color); }

@media (max-width:1150px) {
    .home-tab .tab-header button{min-width: 90px; font-size: 14px; white-space: nowrap;}
    .home-tab .tab-header { overflow: hidden; overflow-x: auto; scrollbar-width: none; border-radius: 30px 30px 0 0; }
}
@media (max-width:767px) {
    .home-tab .tab-content{padding: 15px;}
    .home-tab .tab-header button{flex: none; min-width: auto;}
}
@media (max-width:480px) {
    .home-tab .tab-content{padding: 15px 8px;}
}