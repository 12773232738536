.side-sport-menu { position: fixed; left: 32px; top: calc(50% + 45px); transform: translateY(-50%); z-index: 111; }
.side-sport-menu ul{display: flex; flex-direction: column;}
.side-sport-menu ul::before{background: var(--sidemenu-bg);content: ''; padding-top: 40px; border-top-left-radius: 50px; border-top-right-radius: 50px; }
.side-sport-menu ul:after{background: var(--sidemenu-bg);content: ''; padding-bottom: 40px; border-bottom-left-radius: 50px; border-bottom-right-radius: 50px;}
.side-sport-menu ul li{margin: 0; position: relative; background: var(--sidemenu-bg);}
.side-sport-menu ul li a { display: flex; transition: 0.5s all ease-in-out linear;  min-height: 76px; width: 68px; margin: 0; padding: 0; flex-direction: column; align-items: center; justify-content: center; }
.side-sport-menu ul li:hover a {padding-left: 20px; flex-direction: row; justify-content: flex-start; gap: 5px; white-space: nowrap;}
.side-sport-menu ul li:hover, .side-sport-menu ul li.active{background: transparent;}
.side-sport-menu ul li::after{content: ''; position: absolute; top: 0; left: 0;background-color: var(--sidemenu-bg); mask: url(../../assets/images/Asset.svg) no-repeat 100% 100%; mask-size: 100% 100%; -webkit-mask: url(../../assets/images/Asset.svg) no-repeat 100% 100%; -webkit-mask-size: auto 100%; height: 100%; width: 100%; opacity: 0; transition: none; pointer-events: none;}
.side-sport-menu ul li:hover::after, .side-sport-menu ul li a.active::after{opacity: 1;}
.side-sport-menu ul li a span.icon{ min-width: 42px; min-height: 42px; background: rgba(239, 239, 239, 0.2); border-radius: 50%; display: flex; align-items: center; justify-content: center; margin: 0;}
.side-sport-menu ul li:hover span.icon, .side-sport-menu ul li a.active span.icon{ background: #fff;}
.side-sport-menu ul li a span.icon svg{width: 20px; height: 20px; fill: none;}
.side-sport-menu ul li span.icon svg[fill], .side-sport-menu ul li a span.icon svg[fill]{fill: var(--sidemenu-text);}
.side-sport-menu ul li span.icon svg path[stroke], .side-sport-menu ul li a span.icon svg path[stroke]{stroke: var(--sidemenu-text);}
.side-sport-menu ul li span.icon svg path[fill], .side-sport-menu ul li a span.icon svg path[fill]{fill: var(--sidemenu-text);}
.side-sport-menu ul li span.icon svg circle, .side-sport-menu ul li a span.icon svg circle{stroke: var(--sidemenu-text);}

.side-sport-menu ul li:hover span.icon svg[fill], .side-sport-menu ul li a.active span.icon svg[fill]{fill: var(--sidemenu-text-hover);}
.side-sport-menu ul li:hover span.icon svg path[fill], .side-sport-menu ul li a.active span.icon svg path[fill]{fill: var(--sidemenu-text-hover);}
.side-sport-menu ul li:hover span.icon svg path[stroke], .side-sport-menu ul li a.active span.icon svg path[stroke]{stroke: var(--sidemenu-text-hover);}
.side-sport-menu ul li:hover span.icon svg circle, .side-sport-menu ul li a.active span.icon svg circle{stroke: var(--sidemenu-text-hover);}
.side-sport-menu ul li a span:not(.icon){color: var(--sidemenu-text);padding: 5px; border-radius: 5px; font-size: 12.5px; line-height: 12px; text-align: center;}
.side-sport-menu ul li:hover span:not(.icon), .side-sport-menu ul li a.active span:not(.icon){background: var(--sidemenu-bg); }
body.page-with-side-menu main.main-content, body.page-with-side-menu footer{ padding-left: 120px; }

@media (max-width:1280px) {
    body.page-with-side-menu main.main-content, body.page-with-side-menu footer { padding-left: 80px; }
    .side-sport-menu{left: 16px;}
}
@media (max-height:800px) {
    .side-sport-menu ul li a span:not(.icon){font-size: 11px; padding: 3px; line-height: 10px;}
    .side-sport-menu ul li a span.icon svg { width: 18px; height: 18px; }
    .side-sport-menu ul li a { min-height: 62px; width: 60px; }
    .side-sport-menu ul li a span.icon { min-width: 30px; min-height: 30px; }
    .side-sport-menu ul:after{ padding-bottom: 28px; }
    .side-sport-menu ul::before { padding-top: 28px; }
}
@media (max-width:999px) {
    body.page-with-side-menu main.main-content, body.page-with-side-menu footer { padding-left: 0; }
    .side-sport-menu{display: none;}
}
