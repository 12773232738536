.loginpopup .popup-content{padding: 0; min-height: auto;}
.loginpopup-content { display: flex; justify-content: center; flex-wrap: wrap; position: relative; margin-bottom: 60px;}
.loginpopup-content .lp-row{display: flex; justify-content: center; flex-wrap: wrap; background-color: var(--white-color); border-radius: 30px; overflow: hidden; width: 100%;}
.loginpopup-content .lp-row .lp-col { flex: 0 0 auto; width: 50%; }
.loginpopup-content .lp-row .lp-col .login-banner { height: 100%; }
.loginpopup-content .lp-row .lp-col .login-banner > img{ height: 100%; }
.loginpopup-content .login-form-wraper { padding: 0 1.5rem 15px; }
.loginpopup-content .logo { display: flex; align-items: center; justify-content: center; padding-bottom: 10px; }
.loginpopup-content .logo img {max-height: 120px; max-width: 220px; padding: 8px; }
.loginpopup-content .input-group { margin-bottom: 1rem; }
.loginpopup-content label { font-size: 15px; padding-left: 10px; margin-bottom: .5rem; font-weight: 400;}
.loginpopup-content input:not([type="checkbox"]) {border-radius: 30px; border: 1px solid var(--primary-color); font-size: 15px; font-weight: 400; display: block; width: 100%; padding: .375rem .75rem; line-height: 1.5; height: auto;}
.loginpopup-content .input-group .input-with-icon{ position: relative; display: flex; flex-wrap: wrap; align-items: stretch; width: 100%; }
.loginpopup-content .input-group .input-with-icon .icon { position: absolute; right: 0; top: 0; line-height: 1; height: 100%; display: flex; align-items: center; min-width: 40px; justify-content: center; }
.loginpopup-content .login-btns { display: flex; align-items: center; justify-content: center; gap: 20px; flex-wrap: wrap; }
.loginpopup-content .login-btns button{font-weight: 400; background: var(--primary-gradient); font-size: 15px; padding: 9px 20px;}
.loginpopup-content .login-btns button.demo-loginbtnn{background: transparent; border: 1px solid var(--primary-color); color: var(--primary-color); font-weight: 600;}
.loginpopup-content .login-close{font-weight: 400; position: absolute; bottom: -60px; font-size: 15px; padding: 8px 20px; background: var(--header-bg);}

.signup-popup .loginpopup-content { width:100%; max-width: 500px; margin-left: auto; margin-right: auto;}
.signup-popup .loginpopup-content .lp-row .lp-col{width: 100%;}
.loginpopup-content .input-group.checkbox{display: flex; align-items: center;}
.loginpopup-content .input-group.checkbox label { margin-bottom: 0; line-height: 1;}
.loginpopup-content .input-group.checkbox input { margin: 0; }
.otp-number { display: flex; gap: 5px; margin: 0 0 15px; align-items: center; padding: 5px; }
.otp-number input[type="text"] { width: 35px !important; height: 35px !important; }
.signup-form button.loginbtnn{width: 100%;}
.signup-form .row { margin-right: -8px; margin-left: -8px; }
.signup-form .row .col-6 {padding-right: 8px; padding-left: 8px; }

@media (max-width:999px) {
    .loginpopup-content{margin: 0 auto 60px; max-width: 500px; padding-bottom: 20px;}
    .loginpopup-content .lp-row{flex-direction: column;}
    .loginpopup-content .lp-row .lp-col { width: 100%; }
    .loginpopup-content .login-banner { border-top-left-radius: 30px; border-top-right-radius: 30px; overflow: hidden;}
    .loginpopup-content .lp-row .lp-col .login-banner, .loginpopup-content .lp-row .lp-col .login-banner>img{height: auto;}
}
@media (max-width:767px) {
    .loginpopup-content .login-form-wraper { padding: 0 0.5rem 15px; }
    .login-popup .popup-content{padding: 30px;}
}