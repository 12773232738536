@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css);
/* html { overflow-y: scroll; scrollbar-width: thin; scrollbar-color: #888 #f1f1f1; }
::-webkit-scrollbar { width: 10px; } */


*, ::after, ::before { box-sizing: border-box; scroll-behavior: smooth; vertical-align: middle;}
body{margin: 0; font-family: var(--primary-font); font-weight: 400; line-height: normal; font-size: 15px; background-color: var(--light-color); height: 100%; color: var(--text-color); min-height: 100vh;}
p{margin: 0 0 15px; padding: 0; line-height: normal; }
p a{}
p a:hover{text-decoration: underline; text-underline-offset: 2px; text-decoration-thickness: 2px;}
h1, h2, h3, h4, h5, h6{margin: 0 0 30px; color: var(--text-color);}
h1{font-size: 42px;}
h2{font-size: 36px;}
h3{font-size: 24px;}
h4{font-size: 20px;}
h5{font-size: 18px;}
h6{font-size: 16px;}
a{text-decoration: none; transition: 0.5s; cursor: pointer; outline: 0; color: var(--text-color);}
img{max-width: 100%; vertical-align: middle; transition: 0.5s;}
ul{padding: 0; margin: 0; list-style: none;}
ul li + li{margin-top: 15px;}
strong, b{font-weight: 600;}
button{font-family: var(--primary-font); border: 0; outline: 0; cursor: pointer;}
button.btn{font-family: var(--primary-font); border: 0; outline: 0; background: var(--primary-gradient); cursor: pointer;}
.container{max-width: 1620px; width: 100%; margin-left: auto; margin-right: auto;}
.container-fluid{max-width: 100%; width: 100%; padding-left: 15px; padding-right:15px;}
.green-txt { color: var(--green-color); }
.red-txt { color: var(--red-color); }
/* Bootstrap Css */
.row { display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; margin-right: -15px; margin-left: -15px; }
.no-gutters { margin-right: 0; margin-left: 0; }
.no-gutters > .col,
.no-gutters > [class*="col-"] { padding-right: 0; padding-left: 0; }
[class*="col-"] { position: relative; width: 100%; padding-right: 15px; padding-left: 15px; }
@media (min-width:767px) {
.col-1 { flex: 0 0 8.333333%; max-width: 8.333333%; }
.col-2 { flex: 0 0 16.666667%; max-width: 16.666667%; }
.col-3 { flex: 0 0 25%; max-width: 25%; }
.col-4 { flex: 0 0 33.333333%; max-width: 33.333333%; }
.col-5 { flex: 0 0 41.666667%; max-width: 41.666667%; }
.col-6 { flex: 0 0 50%; max-width: 50%; }
.col-7 { flex: 0 0 58.333333%; max-width: 58.333333%; }
.col-8 { flex: 0 0 66.666667%; max-width: 66.666667%; }
.col-9 { flex: 0 0 75%; max-width: 75%; }
.col-10 { flex: 0 0 83.333333%; max-width: 83.333333%; }
.col-11 { flex: 0 0 91.666667%; max-width: 91.666667%; }
.col-12 { flex: 0 0 100%; max-width: 100%; }
.justify-content-center { justify-content: center !important; }
.justify-content-between { justify-content: space-between !important; }
.justify-content-end { justify-content: flex-end !important; }
.justify-content-between {justify-content: space-between !important; }
.align-items-end { align-items: flex-end !important; }
.align-items-center { align-items: center !important; }
.text-center{text-align: center;}
.text-left{text-align: right;}
.text-right{text-align: right;}
}

.btn{display: inline-flex; align-items: center; justify-content: center; border-radius: 30px; background: var(--primary-gradient); color: var(--white-color); padding: 8px 20px; font-size: 15px; font-weight: 600; transition: all 0.3s ease-in-out; font-family: var(--primary-font); border: 0; cursor: pointer; line-height: 1.5;}
.btn:hover{background: var(--primary-gradient-hover);}
input[type="text"], input[type="email"], input[type="password"], input[type="number"], input[type="date"], select, textarea{background: var(--white-color); font-family: var(--primary-font);height: 38px; border: 1px solid var(--border-color); color: var(--text-color); vertical-align: middle; padding: 5px 15px; width: 100%; font-size: 16px; font-weight: 500;outline: 0; border-radius: 4px;}
textarea{min-height: 150px;}
select{background: url(../images/select-arrow.svg); appearance:none; -moz-appearance: none; -webkit-appearance: none; background-position: center right 10px; background-size: 12px; background-repeat: no-repeat;}
::placeholder{}
label{font-weight: 600; color: var(--text-color); display: block; margin: 0 0 8px;}

.slick-slider .slick-slide{padding: 0 5px;}
.slick-slider .slick-prev{left: 10px;}
.slick-slider .slick-next{right: 10px;}

.banner{margin: 0 0 10px;}
.banner img{border-radius: 30px; width: 100%; aspect-ratio: 1 / 0.227; object-fit: cover; object-position: center;}
.content-full{margin: 10px 0 0;}
.content-w-sidebar { display: grid; grid-template-columns: calc(100% - 16.66666667% - 15px) 16.66666667%; gap: 15px; margin: 10px 0 0; align-items: flex-start;}
.content-w-sidebar .sidebar{ position: sticky; display: flex; flex-direction: column; gap: 10px;}
.content-w-sidebar .sidebar .sidebar-box{padding: 5px; background-color: var(--white-color); border: 1px solid var(--border-color); border-radius: 20px; display: flex; flex-direction: column; gap: 10px; }
.content-w-sidebar .sidebar .sidebar-box .title{text-align: center; background: var(--primary-gradient); border-radius: 30px; padding: 10px 10px; color: var(--white-color); font-size: 18px; line-height: 1;}
.content-w-sidebar .sidebar .sidebar-box .sidebar-card a{display: block;}
.content-w-sidebar .sidebar .sidebar-box .sidebar-card a + a{margin-top: 10px;}
.content-w-sidebar .sidebar .sidebar-box .sidebar-card a img{width: 100%; border-radius: 30px;}

.flash-yellow{ animation: blinker 1.5s linear infinite alternate; }
@keyframes blinker {
0%{background-color: transparent;color: var(--white-color);}
50%{background-color: var(--yellow-color); color: var(--white-color);}
100%{background-color: transparent;color: var(--white-color);}
}

.no-data, .please-wait-data{margin: 0px; text-align: center; padding: 10px 10px; color: black; opacity: 0.4; font-weight: 500; font-size: 18px;}
.please-wait-data{opacity: 0.7;}

/* Dropdown */
.dropdown{position: relative;}
.dropdown .btn{gap: 5px;}
.dropdown .btn svg{ width: 20px; }
.dropdown .btn .profile-img{ width: 25px; }
.dropdown .dropdown-menu { display: none; position: absolute; background: var(--white-color); z-index: 11; min-width: 10rem; right: 0; top: 100%; margin-top: 5px; border-radius: 30px; padding: 10px; border: 1px solid var(--border-color); }
.dropdown .dropdown-menu ul { display: flex; flex-direction: column; align-items: center; justify-content: center; gap: 10px; }
.dropdown .dropdown-menu ul li{margin-top: 0; width: 100%; text-align: center;}
.dropdown .dropdown-menu ul li:not(:last-child){border-bottom: 1px solid var(--border-color);}
.dropdown .dropdown-menu ul li a{font-size: 15px; transition: all 0.3s ease-in-out; text-transform: capitalize;}
.dropdown .dropdown-menu.active { display: block; }

/* Table  */
table{ width: 100%; caption-side: bottom; border-collapse: collapse; border: 0;}
table td, table th { padding: .75rem; vertical-align: top; border-top: 1px solid var(--border-color); line-height: 1;}
tr.back{background: rgb(114 187 239 / 50%) !important;}
tr.back + tr.back{border-top:1px solid rgb(114 187 239 / 30%)!important;}
tr.lay{background: rgb(249 148 186 / 50%)!important;}
tr.lay + tr.lay{border-top:1px solid rgb(249 148 186 / 30%)!important;}

/* React Modal */
.modal { position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: 1050; display: none; overflow: hidden; outline: 0; z-index: 111111; transition: all 0.15s linear;}
.modal button.btn-close:after { content: "\f00d"; font-weight: 900; font-family: "Font Awesome 6 Free"; font-size: 16px;}
.modal-dialog { position: relative; width: auto; max-width: 500px; margin: 1.75rem auto; pointer-events: none; }
.modal-content { position: relative; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; width: 96%; pointer-events: auto; background-color: #fff; background-clip: padding-box; border: 1px solid rgba(0, 0, 0, .2); border-radius: .5rem; outline: 0; margin: auto;}
.modal-header { display: flex; -webkit-box-align: start; -ms-flex-align: start; align-items: flex-start; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; padding: 1rem; border-bottom: 1px solid #e9ecef; border-top-left-radius: .3rem; border-top-right-radius: .3rem; }
.modal-body { position: relative; -webkit-box-flex: 1; -ms-flex: 1 1 auto; flex: 1 1 auto; padding: 1rem; }
.modal-footer { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: end; -ms-flex-pack: end; justify-content: flex-end; padding: 1rem; border-top: 1px solid #e9ecef; }
.modal .modal-dialog.v-center{display: flex; align-items: center; min-height:calc(100vh - 1.75rem - 1.75rem); flex-direction: column;}
.modal-open { overflow: hidden; }
.modal-open .modal { overflow-x: hidden; overflow-y: auto; }
/* Modal Animation */
.fade { transition: all 0.15s linear; }
.fade:not(.show) { opacity: 0; }
.modal.fade .modal-dialog { transition: -webkit-transform .3s ease-out; transition: transform .3s ease-out; transition: transform .3s ease-out, -webkit-transform .3s ease-out; -webkit-transform: translate(0, -50px); transform: translate(0, -50px); }
.modal.show .modal-dialog { -webkit-transform: translate(0, 0); transform: translate(0, 0); }
.modal-backdrop { position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: 11111; background-color: #000; opacity: .5; }

.react-datepicker__input-container:after{content: "\f133"; font-weight: 900; font-family: "Font Awesome 6 Free"; position: absolute; right: 10px; top: 50%; transform: translateY(-50%); pointer-events: none;}
.load-more-btn-sec{text-align: center; margin: 20px 0 0;}

/* Loader */
.loader-sec {position: absolute; left: 0; top: 0; width: 100%; height: 100%; background: #000000b8; text-align: center; display: flex; align-items: center; justify-content: center; gap: 10px; color:var(--white-color); border-radius: 5px; display: none;}
.loader-sec.loading{display: flex;}
.loader-sec .loader-box{display: inline-block; position: relative;}
.loader-sec .loader-box .loader{ width: 26px; height: 26px; border: 2px solid rgb(255 255 255 / 80%); border-top: 2px solid rgba(107, 109, 110, 0.8); border-right: 2px solid rgb(255 255 255 / 80%); border-radius: 50%;  box-shadow: 0 0 15px rgba(91, 94, 95, 0.5); display: inline-block; animation: spin 5s linear infinite;}
.loader-sec .loader-box .loading-number { position: absolute; font-size: 12px; width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; }

@-webkit-keyframes spin {
0% { -webkit-transform: rotate(0deg); }
100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
}



.hide{display: none;}


.skeleton { background-color: #e2e5e7; background-image: linear-gradient( 90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0) ); background-size: 20px 100%; background-repeat: no-repeat; background-position: left -40px top 0; animation: shine 1s ease infinite; min-height: 100px; width: 100%; border-radius: 15px; border: 1px solid var(--border-color); margin-bottom: 20px;}
@keyframes shine {
	to {
		background-position: right -20px top 0;
	}
}

/* Inplay Filter */
.filter-btn-wrapper { width: 100%; display: flex; justify-content: space-between; gap: 10px; vertical-align: middle; margin: 0 0 5px; }
.filter-btn-wrapper .filter-btn { display: flex; align-items: center; gap: 5px; margin-left: auto;}
.filter-btn-wrapper .filter-btn select { background-color: var(--white-color); font-size: 14px; border-radius: 30px; border: 1px solid var(--border-color); width: 130px; outline: 0; height: 30px; padding: 0px 10px; background-size: 10px; }
.filter-btn-wrapper .filter-btn span{line-height: normal; font-size: 14px; white-space: nowrap;}

/* New css */
.sport-all-data .heading.w-star{width: calc(100% - 35px);position: relative;}
.sport-all-data .heading.w-star .star-icon{position: absolute;right: -35px;top:0 ;fill: var(--primary-color);font-size: 18px; vertical-align: middle; padding: 0 3px; display: inline-block; font-weight: 700; }
.sport-all-data .heading.w-star .star-icon path{fill: var(--primary-color);}
.sport-all-data .heading.w-star .star-icon.fill, .sport-all-data .heading.w-star .star-icon.fill path{fill: var(--primary-color);}

/* Keyframes */
@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes blink {
    0% { opacity: 0.2; }
    50% { opacity: 1; }
    100% { opacity: 0.2; }
}
.blink{animation: blink 1s linear infinite alternate; }
.loader { width: 48px; height: 48px; border: 5px solid #FFF; border-bottom-color: var(--primary-color); border-radius: 50%; display: inline-block; box-sizing: border-box; animation: rotation 1s linear infinite; }
 .loader-page { position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: #0000009e; z-index: 123456789; display: flex; align-items: center; justify-content: space-around; }

 /* 16-12-24 */
.live-match-btns { display: flex; align-items: center; justify-content: flex-start; flex-wrap: nowrap; white-space: nowrap; gap: 4px; margin: 5px 0; }
.live-match-btns .btn { gap: 5px; padding: 5px 10px; background: var(--primary-color); line-height: 1; font-weight: 400; font-size: 13px; animation: blink 1800ms infinite; transition: 0.5s;}
.live-match-btns .btn svg { width: 18px; height: 18px;}
.live-match-btns .btn svg, .live-match-btns .btn svg path{fill: #fff;}
/* multimarket */
.multimarket-page h3 { margin: 20px 0 0; }
svg.star-icon { min-width: 23px; }


/* 18-10-24 */
.match-odds.time-tag .box span { background: #D7D7D7; font-size: 16px; pointer-events: none;}
.match-odds.time-tag .box span.live { background: #1fc31f; color: #fff; pointer-events: auto;}


 /* Hourse Racing Popover  */
.match-info-row .title-w-popover { display: inline-flex; align-items: center; gap: 10px; position: relative;}
.match-info-row .title-w-popover .title { font-weight: 500; }
.match-info-row .title-w-popover .title span { display: block; margin: 5px 0 0; opacity: 0.7; font-size: 90%; }
.match-info-row .title-w-popover .horse-number{display: flex; flex-direction: column; text-align: center;}
.match-info-row .title-w-popover .horse-number span:last-child{opacity: 0.7;}
.match-info-row .title-w-popover .icon{min-width: 28px;}
.match-info-row .title-w-popover .popover { position: absolute; left: calc(100% + 20px); top: -5px; display: none; padding: 10px; filter: drop-shadow(2px 4px 6px black); border-color: #e9e9e9; background: #fff; border-radius: 8px; z-index: 1; }
.match-info-row .title-w-popover:hover .popover, .match-info-row .title-w-popover:focus .popover{display: block;}
.match-info-row .title-w-popover .popover:after { content: ''; background: #fff; height: 14px; width: 14px; position: absolute; left: -7px; transform: rotate(45deg); top: 15px; }
.match-info-row .title-w-popover .popover ul.popover-list { list-style: none; padding: 0; margin: 0; }
.match-info-row .title-w-popover .popover ul.popover-list li { display: flex; white-space: nowrap; font-size: 12px; margin: 0; line-height: 18px;}
.match-info-row .title-w-popover .popover ul.popover-list li strong { font-weight: 700; margin-right: 7px; }
.match-info-row .title-w-popover .popover ul.popover-list li strong:after { content: ':'; }
.data-with-betslip:nth-last-of-type(-n+3) .match-info-row .title-w-popover .popover { bottom: 0; top: auto; }
.data-with-betslip:nth-last-of-type(-n+3) .match-info-row .title-w-popover .popover:after{top: auto; bottom: 15px;}


button.btn.sm { padding: 3px 15px; font-size: 12px; font-weight: 500; }
.commission-popup .modal-header h2 { background: var(--primary-gradient); width: 100%; color: #fff; font-size: 16px; padding: 10px; border-radius: 30px; margin: 0; line-height: 1; font-size: 15px; font-weight: 400; text-align: center; }
.commission-popup .modal-header { border-bottom: 0; padding-bottom: 0; }
.commission-popup .account-table{margin-top: 0;}


.commission-popup .modal-dialog { max-width: 1000px; margin-bottom: 60px; }
.commission-popup button.btn-close::after { content: 'close'; font-size: 13px; text-transform: capitalize; color: #fff; line-height: 1; font-family: 'Kanit'; font-weight: 400; }
.commission-popup button.btn-close { font-weight: 400; position: absolute; bottom: -50px; padding: 8px 25px; font-family: var(--primary-font); border: 0; outline: 0; background: var(--primary-gradient); border-radius: 20px; left: 0; right: 0; max-width: 80px; margin: auto; }

/* Inplay Tab */
.allsport-tab { display: flex; gap: 15px; overflow: auto; border-bottom: 5px solid var(--primary-color); margin: 0 0 8px;}
.allsport-tab .tab-btn { background: var(--white-color); padding: 10px 15px; border-top-left-radius: 15px; border-top-right-radius: 15px; flex: auto; white-space: nowrap; display: flex ; align-items: center; justify-content: center; gap: 6px; font-size: 16px; color: var(--text-color); cursor: pointer; position: relative;}
.allsport-tab .tab-btn img { height: 20px; vertical-align: middle; }
.allsport-tab .tab-btn.active{background: var(--primary-color); color: var(--white-color);}

/* Mobile Sport tab */
.mobile-sport-menu { background: var(--white-color); text-align: center; overflow: auto; margin: 0 -15px; padding: 0 0; display: none;}
.mobile-sport-menu ul { display: inline-flex; align-items: center; justify-content: center; margin: 0 auto; gap: 20px;}
.mobile-sport-menu ul li{margin: 0;}
.mobile-sport-menu ul li .mobile-tab-btn { font-weight: 600; text-transform: uppercase; padding: 10px 3px 6px; display: inline-block; font-size: 14px; color: var(--text-color); white-space: nowrap; position: relative; overflow:hidden;}
.mobile-sport-menu ul li .mobile-tab-btn:after{ background: var(--primary-color); height: 3px; width: 100%; position: absolute; bottom: -3px; left: 0; content: ''; opacity: 0; transition: all 0.5s;}
.mobile-sport-menu ul li .mobile-tab-btn.active { color: var(--primary-color); }
.mobile-sport-menu ul li .mobile-tab-btn.active:after { opacity: 1; bottom: 0;}
.mobile-sport-menu ul li .icon { display: flex; width: 32px; height: 32px; margin: 0 auto 4px; align-items: center; justify-content: center; }
.error { color: red; font-size: 12px; margin-left: 5px; }

/* Payment Popup */
.paymentDepositePopup .modal-dialog{width: 96%;}
.paymentDepositePopup .choose-payment-option .payment-tab .payment-tab-list { display: flex; gap: 25px;}
.paymentDepositePopup .choose-payment-option .payment-tab .payment-tab-list .img-payment{display: flex; align-items: center; justify-content: center; border-bottom: 3px solid transparent; padding-bottom: 6px; cursor: pointer;}
.paymentDepositePopup .choose-payment-option .payment-tab .payment-tab-list .img-payment.active { border-bottom: 3px solid var(--primary-color); padding-bottom: 6px; }
.paymentDepositePopup .choose-payment-option .payment-tab .payment-tab-list .img-payment img{max-height: 15px; width: auto; max-width: none;}
.paymentDepositePopup .choose-payment-option .payment-tab .payment-tab-list .img-payment:first-child img{max-height: 25px;}
.paymentDepositePopup .choose-payment-option .payment-tab-content .title { font-size: 18px; font-weight: 500; margin: 20px 0 10px; }
.paymentDepositePopup .choose-payment-option .payment-tab-content .luck-enterprise ul{padding: 12px; margin: 0; list-style: none; background: #f6f6f6;}
.paymentDepositePopup .choose-payment-option .payment-tab-content .luck-enterprise ul li { position: relative; padding-right: 25px;}
.paymentDepositePopup .choose-payment-option .payment-tab-content .luck-enterprise ul li .copy-icon { background: url(../images/copy.png); height: 18px; display: inline-block; background-size: 100%; aspect-ratio: 1/1; opacity: 0.8; position: absolute; right: 0; top: 4px; cursor: pointer;}
.paymentDepositePopup .choose-payment-option .payment-tab-content .luck-enterprise-tag { background: #ffc107; display: inline-block; color: var(--primary-color); padding: 5px 10px 3px; margin: 0 0 15px; text-transform: uppercase; font-size: 14px; border-bottom: 3px solid var(--primary-color); font-weight: 500; }
.paymentDepositePopup .enter-payment-detail { border-top: 1px solid #ddd; margin: 30px 0 0; padding: 20px 0 0; }
.paymentDepositePopup .enter-payment-detail .title { font-size: 18px; font-weight: 500; margin: 0 0 10px; }
.paymentDepositePopup .payment-fom .input-box{ border:0; background-color: transparent; border-radius: 0; flex-direction: column;}
.paymentDepositePopup .payment-fom .input-box input{border: 1px solid var(--primary-color); background-color: var(--white-color); border-radius: 30px; width: 100%; }
.paymentDepositePopup .payment-fom .input-box>span.input_err { font-size: 12px; color: red; padding: 0 15px; }
.accountnamecontainer { width: 100%; display: inline-block; margin: 0 0 0; }
.accountnamecontainer .luck-enterprise-tag { border-bottom: 3px solid transparent !important; margin-right: 12px !important; cursor: pointer; }
.accountnamecontainer .luck-enterprise-tag.active { color: #000 !important; border-bottom: 3px solid var(--color2) !important; }
.accountnamecontainer .luck-enterprise-tag:last-child { margin-right: 0 !important; }

.paymentDepositePopup .choose-payment-option .payment-tab .payment-tab-list{gap: 0 25px; flex-wrap: wrap;}
.paymentDepositePopup .choose-payment-option .payment-tab .payment-tab-list .payment-mng-link { margin-left: auto; color: #1533ab; text-decoration: underline; line-height: 1; font-weight: 500; padding-bottom: 0;}

input[type=file]{padding: 0 !important;}
input[type=file]::-webkit-file-upload-button,  input[type=file]::file-selector-button { background: #f8f9fa; border: 0; border-right: 1px solid var(--primary-color); padding: 7px 10px;}

.manage-accounts-sec .acount-stat-ro-titlselect-form .site-button{padding: 4px 15px;}
.manage-acccount-popup .choose-payment-option .payment-tab .payment-tab-list { margin: 0 0 10px; background-color: var(--primary-color); border-radius: 20px; padding: 6px; gap: 8px;}
.manage-acccount-popup .choose-payment-option .payment-tab .payment-tab-list .img-payment{ border-bottom: 0; cursor: pointer; color: var(--white-color); font-weight: 400; font-size: 15px; padding: 0 10px; border-radius: 30px; text-transform: uppercase;}
.manage-acccount-popup .choose-payment-option .payment-tab .payment-tab-list .img-payment.active{border-bottom: 0; padding-bottom: 0; background: var(--white-color); color: var(--primary-color); font-weight: 500;}
.manage-acccount-popup .enter-withdraw-detail .input-box.hide{display: none;}

/* 20-1-25 */
.sport-all-data .heading .odds-heading{gap: 4px;}
.inner-match-list.sport-all-data .match-heading .odds-heading .heading-text{padding: 4px 10px; line-height: 1; border-radius: 5px; max-width: 62px; width: 100%; justify-content: center; align-items: center; margin: 3px 0 0; font-size: 14px; min-height: 24px; transition: 0.45s;}
.inner-match-list.sport-all-data .match-heading .odds-heading .heading-text.blue{ background: #4c9ed9;}
.inner-match-list.sport-all-data .match-heading .odds-heading .heading-text.pink{background: #ed79a5;}
.inner-match-list.sport-all-data .match-heading .odds-heading .heading-text:hover, .match-odds .box span:hover{ box-shadow: inset 0 0 0 30px rgb(0 0 0 / 25%); }
.sport-all-data.inner-match-list.fancylist .match-heading .heading-text{color: var(--text-color) !important; margin-bottom: 3px;}
.sport-all-data .szh-accordion__item-btn{ background: transparent; width: 100%; padding: 0; margin: 0; vertical-align: middle;}
.sport-all-data .szh-accordion__item-content { transition: height 0.35s cubic-bezier(0, 0, 0, 1); }
.sport-all-data .szh-accordion__item-btn .heading { padding-left: 28px; position: relative; }
.sport-all-data .szh-accordion__item-btn .heading:before { content: ''; display: inline-block; border-left: 2px solid var(--white-color); border-bottom: 2px solid var(--white-color); padding: 3px; transform: rotate(135deg); cursor: pointer; margin-left: auto; position: absolute; left: 12px; top: 12px; }
.sport-all-data .szh-accordion__item--status-exited .szh-accordion__item-btn .heading:before{transform: rotate(-45deg); top: 8px;}
.sport-all-data  .szh-accordion__item-heading{line-height: 1; font-size: inherit;}

/* 21-1-25 */
.sport-all-data.inner-match-list .heading .odds-heading{justify-content: center;}
.sport-all-data.inner-match-list.fancylist .szh-accordion__item-btn .match-heading{padding-left: 28px; position: relative;}
.sport-all-data.inner-match-list.fancylist .szh-accordion__item-btn .match-heading:before{border-color: var(--text-color) !important;}

/* 22-1-24 */
.account-table .btn-set{ display: flex; align-items: center; gap: 3px; justify-content: center; }
.account-table .btn-set .btn { padding: 4px 15px; font-weight: 400; }
.account-table .btn-set .btn.delete-btn { background: #cb1b1c; }
.payment-mng-link{color: var(--primary-color);}

/* 23-1-25 */
.sticky-link { position: fixed; bottom: 10px; right: 10px; z-index: 99999; display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-end; gap: 4px; }
.sticky-link .sticky-bets{  background: var(--primary-color); z-index: 1111; height: 50px; width: 50px; border-radius: 55px; text-align: center; line-height: 1; display: flex; align-items: center; justify-content: center; flex-direction: column; font-size: 14px; filter: drop-shadow(0px 0px 20px #ddd); cursor: pointer;}
.sticky-link .sticky-bets .count { color: #fff; font-weight: 600; font-size: 140%; }
.sticky-link .sticky-bets .label { font-size: 100%; color: #fff; font-weight: 300; }
.sticky-link .whatsup-app-link { margin-right: 4px; }
.sticky-link .whatsup-app-link a { display: flex; }
.sticky-link .whatsup-app-link a img.whatsapp_icon_alg { z-index: 2; width: 40px; border-radius: 50px; height: 40px; }
.sticky-link .whatsup-app-link a .whatsapp_font_alg { width: 220px; height: 40px; border-radius: 25px; opacity: 0; padding: 5px 20px 4px; color: #fff; background: #25d366; margin-right: -40px; padding-right: 40px; font-size: 14px; line-height: 1; display: flex; align-items: center; transition: 0.5s;}
.sticky-link .whatsup-app-link a:hover .whatsapp_font_alg { opacity: 1;  transition: 0.5s;}

.oneclick-bt {
	background: #f39c12;
	color: #fff;
	padding: 8px 20px;
	font-size: 15px;
	font-weight: 600;
	border: none;
	border-radius: 30px;
	cursor: pointer;
	transition-duration: 0.4s;
}

.oneclick-bt:hover {
	background: #fff;
	box-shadow: 0px 2px 20px -3px #97B1BF;
	color: #000;
}

.diposit-bt {
	background: #f39c12;
	color: #fff;
	padding: 8px 20px;
	font-size: 15px;
	font-weight: 600;
	border: none;
	border-radius: 30px;
	cursor: pointer;
	transition-duration: 0.4s;
}

.diposit-bt:hover {
	background: #fff;
	box-shadow: 0px 2px 20px -3px #97B1BF;
	color: #000;
}

/* 29-1-25 */
.user-profile.full-w{grid-template-columns: 100%;}
.mobile-sport-menu ul li .mobile-tab-btn .live-count, .allsport-tab .tab-btn .live-count{ position: absolute; right: 2px; top: 4px; background: #19b719; height: 18px; width: 18px; color: #fff; border-radius: 20px; font-size: 11px; display: flex; align-items: center; justify-content: center; }
.allsport-tab .tab-btn{margin-top: 4px;}
.allsport-tab .tab-btn .live-count{right: 4px; top: -4px;}
.copy-icon { background: url(../images/copy.png); height: 18px; display: inline-block; background-size: 100%; aspect-ratio: 1/1; opacity: 0.8; cursor: pointer;}


.casino-welcome-modal .cw-wraper { text-align: center; padding: 20px 0 30px; }
.casino-welcome-modal .cw-wraper h2 { text-shadow: 0 7px 10px #00000073; font-size: 80px; line-height: 1; margin: 0;}
.casino-welcome-modal .cw-wraper h2 span{font-weight: 400; display: block; font-size: 45px; text-shadow: none; margin: 0 0 20px;}
.casino-welcome-modal .cw-wraper h2 small{font-size: 100%;}
.casino-welcome-modal .note-point { padding:10px 0;}
.casino-welcome-modal .note-point .point { font-size: 40px; font-weight: 700; text-shadow: 0 7px 10px #00000073; margin: 0 0 15px;}
.casino-welcome-modal .note-point p { margin: 0; font-size: 24px;}
.casino-welcome-modal .devider img{max-width: 90%}
.casino-welcome-modal .limit-bet { text-align: center; font-size: 28px; display: flex; flex-direction: column; font-weight: 500; text-transform: capitalize; line-height: 1.2; }
.casino-welcome-modal .agree-btn span { background: #000; color: #fff; padding: 12px 25px 14px; display: inline-block; margin: 30px 0 0; font-weight: 600; text-transform: uppercase; border-radius: 30px; line-height: 1; font-size: 18px; box-shadow: inset 1px 3px 30px 10px #6d6d6de3, 0px 10px 15px 2px #0000003b; cursor: pointer; }
.casino-welcome-modal .modal-header { position: absolute; right: -8px; border: 0; padding: 0; top: -8px; }
.casino-welcome-modal .modal-header button.btn-close { background: #000; color: #fff; z-index: 1; height: 26px; padding: 0; width: 26px; line-height: 1; border-radius: 30px; }
@media (max-width:767px) {
    .casino-welcome-modal .cw-wraper { padding: 10px 0 20px; }
    .casino-welcome-modal .cw-wraper h2 span{font-size: 30px; margin: 0 0 10px;}
    .casino-welcome-modal .cw-wraper h2{font-size: 50px;}
    .casino-welcome-modal .note-point .point{font-size: 30px; margin: 0 0 10px;}
    .casino-welcome-modal .note-point p { font-size: 20px; }
    .casino-welcome-modal .limit-bet{font-size: 22px;}
    .casino-welcome-modal .agree-btn a { padding: 10px 25px 12px; margin: 20px 0 0; font-size: 16px; }
}